import React, { Component } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  root: {
    height: '100px',
    width: '100%',
    background: theme.palette.grey.main,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 'auto'
    }
  },
  mobileNavButtonDiv: {
    display: 'none',
    // width: '100%',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    top: '32px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      zIndex: 1
    }
  },
  mobileNavButton: {
    height: '34px',
    width: '44px',
    marginLeft: 'auto',
    marginRight: theme.spacing(2)
  },
  mobileNavButtonRoot: {
    borderRadius: 0,
    border: '1px solid #ddd',
    padding: 0
  },
  nav: {
    width: theme.container.width.default,
    display: 'inline-flex',
    [theme.breakpoints.down('md')]: {
      width: theme.container.width.md
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  mobileNav: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  navItem: {
    flex: 1,
    textAlign: 'center'
  },
  navLink: {
    marginTop: '25px',
    letterSpacing: '3px',
    fontWeight: 400,
    fontSize: '20px',
    textTransform: 'uppercase',
    fontFamily: "'Roboto Condensed',sans-serif",
    transition: '0.5s',
    borderBottom: `4px solid ${theme.palette.grey.main}`,
    borderRight: `1px solid ${theme.palette.secondary.main}`,
    borderLeft: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.primary.main,
    display: 'block',
    lineHeight: '1.42857143',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.secondary.main
    }
  }
});

const Nav = ({ classes }) => (
  <nav className={classes.nav}>
    <div className={classes.navItem}>
      <a href="http://defoxx.com" className={classes.navLink}>
        HOME
      </a>
    </div>
    <div className={classes.navItem}>
      <a href="http://defoxx.com/about-us/" className={classes.navLink}>
        ABOUT US
      </a>
    </div>
    <div className={classes.navItem}>
      <a href="http://defoxx.com/our-brands/" className={classes.navLink}>
        OUR BRANDS
      </a>
    </div>
    <div className={classes.navItem}>
      <a href="http://defoxx.com/contact-us/" className={classes.navLink}>
        CONTACT US
      </a>
    </div>
    <div className={classes.navItem}>
      <a href="https://defoxx.cinatomic.com/careers" className={classes.navLink}>
        CAREERS
      </a>
    </div>
  </nav>
);

export class NavBar extends Component {
  state = {
    showMobileNav: false
  };

  toggleMobileNav = () => {
    const { showMobileNav } = this.state;
    this.setState({ showMobileNav: !showMobileNav });
  };

  render() {
    const { classes } = this.props;
    const { showMobileNav } = this.state;
    return (
      <header className={classes.root}>
        <Nav
          classes={{
            ...classes
          }}
        />
        {/* <nav className={classes.nav}>
          <div className={classes.navItem}>
            <a href="http://defoxx.com" className={classes.navLink}>
              HOME
            </a>
          </div>
          <div className={classes.navItem}>
            <a href="http://defoxx.com/about-us/" className={classes.navLink}>
              ABOUT US
            </a>
          </div>
          <div className={classes.navItem}>
            <a href="http://defoxx.com/our-brands/" className={classes.navLink}>
              OUR BRANDS
            </a>
          </div>
          <div className={classes.navItem}>
            <a href="http://defoxx.com/contact-us/" className={classes.navLink}>
              CONTACT US
            </a>
          </div>
          <div className={classes.navItem}>
            <a href="https://defoxx.cinatomic.com/careers" className={classes.navLink}>
              CAREERS
            </a>
          </div>
        </nav> */}

        <Hidden mdUp>
          <Collapse in={showMobileNav} collapsedHeight="0px" style={{ width: '100%' }}>
            <Nav
              classes={{
                ...classes,
                nav: classes.mobileNav
              }}
            />
            {/* <nav className={classes.mobileNav}>
              <div className={classes.navItem}>
                <a href="http://defoxx.com" className={classes.navLink}>
                  HOME
                </a>
              </div>
              <div className={classes.navItem}>
                <a href="http://defoxx.com/about-us/" className={classes.navLink}>
                  ABOUT US
                </a>
              </div>
              <div className={classes.navItem}>
                <a href="http://defoxx.com/our-brands/" className={classes.navLink}>
                  OUR BRANDS
                </a>
              </div>
              <div className={classes.navItem}>
                <a href="http://defoxx.com/contact-us/" className={classes.navLink}>
                  CONTACT US
                </a>
              </div>
              <div className={classes.navItem}>
                <a href="https://defoxx.cinatomic.com/careers" className={classes.navLink}>
                  CAREERS
                </a>
              </div>
            </nav> */}
          </Collapse>
        </Hidden>
        <div className={classes.mobileNavButtonDiv}>
          <IconButton
            disableRipple={true}
            disableFocusRipple={true}
            className={classes.mobileNavButton}
            classes={{ root: classes.mobileNavButtonRoot }}
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={this.toggleMobileNav}
          >
            <MenuIcon />
          </IconButton>
        </div>
      </header>
    );
  }
}

export default withStyles(styles)(NavBar);
