import React from 'react';
import { useTheme } from '@material-ui/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Hidden from '@material-ui/core/Hidden';

export const MobileStep = ({ steps, activeStep, handleNext, handleBack, backDisabled, nextDisabled, nextText }) => {
  const theme = useTheme();
  return (
    <Hidden mdUp>
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
        <MobileStepper
          steps={steps.length}
          position="static"
          variant="text"
          activeStep={activeStep}
          nextButton={
            <Button size="small" onClick={handleNext} disabled={nextDisabled}>
              {nextText ? nextText : 'Next'}
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={backDisabled}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              Back
            </Button>
          }
        />
      </div>
    </Hidden>
  );
};

export default MobileStep;
