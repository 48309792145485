import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import PrintIcon from '@material-ui/icons/Print';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import { PDFExport } from '@progress/kendo-react-pdf';
import { map } from './mapApplicationData';
import Resume from './Resume';

const styles = theme => ({
  rootRoot: {
    padding: theme.spacing(2),
    paddingTop: 0
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  table: {
    padding: theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderBottom: '2px solid #f4f6f8',
    display: 'flex'
  },
  tableTitle: {
    color: '#898e9b',
    width: '200px'
  },
  fieldContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  fieldRow: {
    display: 'flex',
    flex: 1,
    marginBottom: theme.spacing(1.5)
    // justifyContent: 'space-between'
  },
  rowDescription: {
    color: '#898e9b',
    marginBottom: theme.spacing(1)
  },
  field: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginRight: theme.spacing(2)
  },
  fieldLabel: {
    color: '#898e9b'
  },
  fieldValue: {
    flex: '0 0 34px',
    background: '#e7f2fb',
    minWidth: '34px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(0.5),
    borderRadius: '5px',
    wordBreak: 'break-all'
  },
  headerButtonContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  headingContainer: {
    background: '#e7f2fb',
    display: 'flex',
    flex: 1
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    minHeight: '56px'
  },
  button: {
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1),
    fontSize: 20
  }
});

function ITable(props) {
  const { data, classes } = props;
  return (
    <div className={`${classes.table} application-data-table`}>
      <div className={classes.tableTitle}>
        <Typography variant="body1">{data.title}</Typography>
      </div>
      <div className={classes.fieldContainer}>
        {data.rows.map((row, i) => {
          return (
            <Fragment key={`${i}-row`}>
              {!!row.description && (
                <div className={classes.rowDescription}>
                  <Typography variant="h6">{row.description || ' '}</Typography>
                </div>
              )}

              <div className={classes.fieldRow}>
                {row.fields.map((field, i) => {
                  return <Field key={`${i}-field`} data={field} classes={classes} />;
                })}
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}

function Field(props) {
  const { data, classes } = props;
  return (
    <div className={classes.field}>
      <div className={classes.fieldLabel}>
        <Typography variant="caption">{data.label}</Typography>
      </div>
      <div className={classes.fieldValue}>
        <Typography variant="body1">{data.value || ' '}</Typography>
      </div>
    </div>
  );
}

export class ApplicationDisplay extends Component {
  defaultState = {
    application: null,
    resumeFilePath: '',
    resumeFileName: '',
    openArchiveModal: false,
    archiveInProgress: false
  };

  state = { ...this.defaultState };

  fetchApplication = applicationId => {
    firebase
      .firestore()
      .collection('applications')
      .doc(applicationId)
      .get()
      .then(doc => {
        if (doc.exists) {
          const rawData = doc.data();
          const data = map(rawData);
          const { resumeFileName = '', resumeFilePath = '', firstName = '', lastName = '', position = '', createdAt } = rawData;
          const fullName = `${firstName} ${lastName}`;
          let createdDate = '';

          if (createdAt) {
            const asMoment = new moment(createdAt.toDate());
            createdDate = asMoment.format('MMMM Do YYYY, h:mma');
          }

          this.setState({ application: data, resumeFileName, resumeFilePath, fullName, position, createdDate });
        }
      })
      .catch(error => {});
  };

  componentDidUpdate(prevProps) {
    const { selectedApplicationId } = this.props;
    if (!selectedApplicationId && selectedApplicationId !== prevProps.selectedApplicationId) {
      this.setState(this.defaultState);
    } else if (selectedApplicationId && selectedApplicationId !== prevProps.selectedApplicationId) {
      this.fetchApplication(selectedApplicationId);
    }
  }

  exportPDF = () => {
    this.applicationForm.save();
  };

  handleCloseArchiveModal = () => {
    this.setState({ openArchiveModal: false });
  };

  handleOpenArchiveModal = () => {
    this.setState({ openArchiveModal: true });
  };

  archiveApplication = () => {
    const { handleArchiveApplication, selectedApplicationId } = this.props;
    this.setState({ archiveInProgress: true }, () => handleArchiveApplication(selectedApplicationId));
  };

  render() {
    const { classes } = this.props;
    const { application, resumeFileName, resumeFilePath, openArchiveModal, archiveInProgress } = this.state;

    if (!application) {
      return (
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Typography variant="h2" gutterBottom style={{ color: '#e6e6e6' }}>
            Select an application from the left
          </Typography>
        </div>
      );
    }

    return (
      <div>
        <div className={classes.rootRoot}>
          <div className={classes.toolbar}>
            <Button variant="contained" color="default" size="small" className={classes.button} onClick={this.exportPDF}>
              <PrintIcon className={classes.leftIcon} />
              Print Application
            </Button>
            {/* <button onClick={this.exportPDF}>download</button> */}

            <div style={{ marginLeft: '12px' }}>
              <Resume resumeFileName={resumeFileName} resumeFilePath={resumeFilePath} />
            </div>

            <div style={{ marginLeft: '12px' }}>
              <Button variant="outlined" size="small" color="primary" onClick={this.handleOpenArchiveModal}>
                Archive Application
              </Button>
            </div>
          </div>
          <div className={classes.headingContainer}>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <Typography variant="h4" style={{ paddingTop: '2rem', paddingLeft: '200px' }}>
                {this.state.fullName}
              </Typography>

              <Typography variant="body1" className={classes.tableTitle} style={{ paddingBottom: '2rem', paddingLeft: '200px' }}>
                {this.state.position}
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'flex-end' }}>
              <Typography variant="body1" style={{ paddingTop: '2rem', paddingRight: '200px', color: '#898e9b' }}>
                Submission Date
              </Typography>
              <Typography variant="body1" style={{ paddingBottom: '2rem', paddingRight: '200px' }}>
                {this.state.createdDate}
              </Typography>
            </div>
          </div>
          <PDFExport
            paperSize={'Letter'}
            fileName={`${this.state.fullName}-${this.state.position}-application.pdf`}
            title=""
            subject=""
            keywords=""
            scale={0.5}
            keepTogether=".application-data-table"
            // forcePageBreak=".SimpleTable-table-2"
            ref={r => (this.applicationForm = r)}
          >
            <div>
              {application.tables.map((table, i) => {
                return <ITable key={`${i}-iTable`} data={table} classes={classes} />;
              })}
            </div>
          </PDFExport>
        </div>
        <Dialog
          open={openArchiveModal}
          onClose={this.handleCloseArchiveModal}
          aria-labelledby="archive-dialog-title"
          aria-describedby="archive-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Archive this application?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Are you sure you want to archive this application?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseArchiveModal} variant="outlined" color="primary">
              No, don't archive
            </Button>
            <Button disabled={archiveInProgress} onClick={this.archiveApplication} color="primary" variant="outlined" autoFocus>
              {archiveInProgress ? '..archiving..' : 'Yes, archive it'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(ApplicationDisplay);
