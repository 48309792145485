import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import UploadIcon from '@material-ui/icons/CloudUploadOutlined';
import MobileStep from './MobileStep';
import Hidden from '@material-ui/core/Hidden';
import firebase from 'firebase/app';
import 'firebase/storage';
import UUID from 'uuid-v4';

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'column'
  },
  buttonContainer: {
    marginTop: 'auto',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  headingContainer: {
    borderBottom: '1px solid grey',
    marginBottom: theme.spacing(2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  textField: {
    backgroundColor: '#fff',
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0
    }
  },
  uploadInput: {
    display: 'none'
  },
  lineItemTextField: {
    backgroundColor: '#fff',
    marginLeft: '2rem',
    marginRight: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto'
    },
    '&:last-child': {
      marginRight: 0
    }
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2)
  },
  sectionFieldsContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  lineItemContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  lineItemText: {
    [theme.breakpoints.down('sm')]: {
      marginRight: '2rem'
    }
  },
  uploadContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2)
  }
});

const UploadItem = props => {
  const { classes, fileName, uploadFile, uploadInProgress, resumeUploadError } = props;

  return (
    <div className={classes.uploadContainer}>
      {fileName && (
        <Typography variant="body1" color="secondary" className={classes.fileName}>
          {fileName}
        </Typography>
      )}
      {uploadInProgress && (
        <Typography variant="body1" className={classes.fileName}>
          ...uploading...
        </Typography>
      )}
      {resumeUploadError && (
        <Typography variant="body1" color="error" className={classes.fileName}>
          Error: Please make sure your resume is less than 25mb
        </Typography>
      )}
      <input
        className={classes.uploadInput}
        id={`resume-upload`}
        type="file"
        onClick={event => (event.target.value = null)}
        onChange={event => uploadFile(event)}
      />

      <label htmlFor={`resume-upload`}>
        <Tooltip title="Upload">
          <IconButton aria-label="Upload" component="span">
            <UploadIcon />
          </IconButton>
        </Tooltip>

        <span>Max file size: 25mb</span>
      </label>
    </div>
  );
};

export class Resume extends Component {
  state = {
    uploadInProgress: false,
    resumeUploadError: false,
    resumeFilePath: '',
    resumeFileName: '',
    initials: '',
    signature: '',
    applicationDate: '',
    isComplete: false
  };

  componentDidMount() {
    const { globalState } = this.props;

    window.scrollTo(0, 0);

    this.setState(
      {
        resumeFilePath: globalState.resumeFilePath || '',
        resumeFileName: globalState.resumeFileName || '',
        initials: globalState.initials || '',
        signature: globalState.signature || '',
        applicationDate: globalState.applicationDate || ''
      },
      this.processStep
    );
  }

  handleFieldChange = ({ target = {} }) => {
    const name = target.name;
    const value = target.value;

    this.setState({ [name]: value }, this.processStep);
  };

  processStep = () => {
    const { updateGlobalState } = this.props;
    const { resumeFilePath, resumeFileName, initials, signature, applicationDate } = this.state;
    const isComplete = !!initials && !!signature && !!applicationDate;
    updateGlobalState({ resumeFilePath, resumeFileName, initials, signature, applicationDate });
    this.setState({ isComplete });
  };

  uploadFile = event => {
    const file = event.target.files[0];
    const uuid = UUID();
    const path = `resumes/${uuid}/${file.name}`;
    const storageRef = firebase.storage().ref();

    this.setState({ uploadInProgress: true, resumeFileName: '', resumeUploadError: false });

    storageRef
      .child(path)
      .put(file)
      .then(snapshot => {
        const resumeFilePath = snapshot.ref.fullPath || path;
        const resumeFileName = file.name;

        this.setState({ resumeFilePath, resumeFileName, uploadInProgress: false, resumeUploadError: false }, this.processStep);
      })
      .catch(error => {
        this.setState({ resumeUploadError: true, uploadInProgress: false, resumeFileName: '' });
      });
  };

  render() {
    const { handleBack, submitApplication, classes } = this.props;
    const { isComplete } = this.state;
    return (
      <div className={classes.container}>
        <div className={classes.headingContainer}>
          <Typography variant="h5" gutterBottom color="primary">
            Upload your resume and authorize consent
          </Typography>
        </div>
        <div className={classes.sectionContainer}>
          <Typography variant="h6" color="primary">
            Equal Opportunity Employer
          </Typography>
          <Typography variant="body1" color="primary">
            This employer does not discriminate in hiring or employment on the basis of age, race, color, sex, religion, national origin, disability,
            military, veteran status or any other classification protected by federal, state or local law.
          </Typography>
        </div>
        <div className={classes.sectionContainer}>
          <Typography variant="h6" color="primary">
            Authorization and Consent for Release of Information
          </Typography>
          <Typography variant="body1" color="primary">
            I hereby certify that the statements contained in this application or other documents submitted and the statements made during any
            interview process are true and complete to the best of my knowledge, and I understand that material omissions from or false statements
            made in this application or other documents submitted or occurring during any interview process shall be grounds for denial of employment
            or discharge. I authorize the company to conduct a background check and to investigate references provided and all statements contained in
            this application or any other documents submitted or made during any interview process, and I release all parties from all liability for
            any damage that may result from furnishing any information to the Company. I also indemnify the company against any liability which might
            result from making such investigation.
          </Typography>
          <Typography variant="body1" color="primary">
            I also consent to voluntarily submit to and authorize the company, through a physician chosen by it, to conduct a post-offer,
            pre-employment medical examination as a condition of employment. I also consent to voluntarily submit to and authorize the Company to
            conduct post-offer, pre-employment tests for the illegal use of drugs and I understand that the failure to pass such tests may be grounds
            for denial of employment or termination, if employed at the time. I also agree to indemnify and hold the Company harmless from any
            liability which might result from conducting such examination or tests.{' '}
          </Typography>
          <Typography variant="body1" color="primary">
            I understand and agree that nothing stated in this employment application, in any other document, or in an interview is intended to create
            an employment contract between the company and myself for either employment or for the providing of any benefit. Likewise, no such
            contracts are intended to be created from the mere granting of an interview. No promises or guarantees regarding employment have been made
            to me, and I understand that no such promise or guarantee is binding upon the company unless made in writing by the CEO of this company
            with approval from the Board of Directors and then only for the time specified or, if none is specified, one week.{' '}
          </Typography>
          <Typography variant="body1" color="primary">
            I understand and agree that, if I am hired, my employment is for no definite period of time. I further understand and agree that,
            regardless of the date of payment of wages, salary or benefits, my employment, including wages, salary or benefits, may be terminated with
            or without cause and with or without notice at any time by the company. In addition, I understand that I likewise may terminate my
            employment with or without cause and with or without notice at any time.{' '}
          </Typography>

          <div className={classes.sectionFieldsContainer}>
            <div className={classes.lineItemContainer}>
              <Typography variant="body1" color="primary" className={classes.lineItemText}>
                I understand and accept the terms described above.
              </Typography>
              <TextField
                required
                id="resume-initials"
                label="Your Initials"
                name="initials"
                className={classes.lineItemTextField}
                margin="dense"
                variant="outlined"
                value={this.state.initials}
                onChange={this.handleFieldChange}
              />
            </div>
          </div>

          <div className={classes.sectionFieldsContainer} style={{ marginTop: '1rem' }}>
            <div className={classes.lineItemContainer}>
              <Typography variant="body1" color="primary" className={classes.lineItemText}>
                Please upload your resume:
              </Typography>
              <UploadItem
                classes={classes}
                fileName={this.state.resumeFileName}
                uploadFile={this.uploadFile}
                uploadInProgress={this.state.uploadInProgress}
                resumeUploadError={this.state.resumeUploadError}
              />
            </div>
          </div>

          <div className={classes.sectionFieldsContainer} style={{ marginTop: '2rem' }}>
            <div className={classes.lineItemContainer}>
              <Typography variant="body1" color="primary" className={classes.lineItemText}>
                Sign and Date:
              </Typography>
              <TextField
                required
                id="resume-signature"
                label="Your Signature"
                name="signature"
                placeholder="Your Full Name"
                className={classes.lineItemTextField}
                margin="dense"
                variant="outlined"
                value={this.state.signature}
                onChange={this.handleFieldChange}
              />
              <TextField
                required
                id="resume-date"
                label="Date"
                name="applicationDate"
                placeholder="MM/DD/YYYY"
                className={classes.lineItemTextField}
                margin="dense"
                variant="outlined"
                value={this.state.applicationDate}
                onChange={this.handleFieldChange}
              />
            </div>
          </div>
        </div>

        <div className={classes.buttonContainer}>
          <Hidden smDown>
            <Button onClick={handleBack} className={classes.backButton}>
              Back
            </Button>
            <Button disabled={!isComplete} variant="contained" color="secondary" onClick={submitApplication}>
              Submit Application
            </Button>
          </Hidden>
          <MobileStep
            backDisabled={false}
            nextDisabled={!isComplete}
            steps={this.props.steps}
            activeStep={this.props.activeStep}
            handleNext={submitApplication}
            handleBack={handleBack}
            nextText="Submit Application"
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Resume);
