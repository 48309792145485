import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as firebase from 'firebase/app';
import JobPostingsMain from './JobPostingsMain';
import EditPosting from './EditPosting';
import { Switch, Route } from 'react-router-dom';

const styles = theme => ({
  root: {
    height: 'auto',
    // paddingLeft: '240px',
    width: '100%',
    paddingTop: '64px'
    // display: 'flex',
    // flex: '0 0 auto'
  },
  headerRoot: {
    flexGrow: 1
  },
  appBar: {
    boxShadow: 'none'
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  container: {
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column',
    alignItems: 'center'
  }
});

export class JobPostings extends Component {
  state = {};

  doLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(function() {
        // Sign-out successful.
      })
      .catch(function(error) {
        // An error happened.
      });
  };

  render() {
    const { match, classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.headerRoot}>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Job Postings
              </Typography>
              <Button variant="contained" color="primary" href="/dashboard">
                Applications
              </Button>
              <Button color="inherit" onClick={this.doLogout}>
                Logout
              </Button>
            </Toolbar>
          </AppBar>
        </div>
        <Switch>
          <Route exact path={match.path} component={JobPostingsMain} />
          <Route exact path={`${match.path}/new-posting`} component={EditPosting} />
          <Route exact path={`${match.path}/edit-posting/:jobPostingId`} component={EditPosting} />
        </Switch>
      </div>
    );
  }
}

export default withStyles(styles)(JobPostings);
