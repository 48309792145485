import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import queryString from 'query-string';
import Sidebar from './Sidebar';
import MainContainer from './MainContainer';
import * as firebase from 'firebase/app';
import 'firebase/auth';

const styles = theme => ({
  root: {
    height: '100%',
    paddingLeft: '240px',
    paddingTop: '64px'
  },
  headerRoot: {
    flexGrow: 1
  },
  appBar: {
    boxShadow: 'none'
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  }
});

export class AdminDashboard extends Component {
  state = {
    selectedApplicationId: null
  };

  componentDidMount() {
    this.parseParams();
  }

  updateSelectedApplication = applicationId => {
    this.setState({ selectedApplicationId: applicationId });
  };

  handleArchiveApplication = applicationId => {
    const db = firebase.firestore();
    const batch = db.batch();
    const indexRef = db.collection('applicationsIndex').doc(applicationId);
    const applicationRef = db.collection('applications').doc(applicationId);

    batch.update(indexRef, { archived: true });
    batch.update(applicationRef, { archived: true });

    batch.commit().then(() => {
      this.updateSelectedApplication(null);
    });
  };

  parseParams = () => {
    const { location } = this.props;
    const parsed = queryString.parse(location.search) || {};

    if (parsed.applicationId) {
      this.updateSelectedApplication(parsed.applicationId);
    }
  };

  doLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(function() {
        // Sign-out successful.
      })
      .catch(function(error) {
        // An error happened.
      });
  };

  render() {
    const { classes } = this.props;
    const { selectedApplicationId } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.headerRoot}>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Applications
              </Typography>
              <Button variant="contained" color="primary" href="/job-postings">
                Job Postings
              </Button>

              <Button color="inherit" onClick={this.doLogout}>
                Logout
              </Button>
            </Toolbar>
          </AppBar>
        </div>

        <Sidebar updateSelectedApplication={this.updateSelectedApplication} />

        <MainContainer selectedApplicationId={selectedApplicationId} handleArchiveApplication={this.handleArchiveApplication} />
      </div>
    );
  }
}

export default withStyles(styles)(AdminDashboard);
