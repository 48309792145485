import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import JobTable from './JobTable';

const styles = theme => ({
  container: {
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column',
    alignItems: 'center'
  }
});

export class JobPostingsMain extends Component {
  state = {};

  render() {
    const { match, classes } = this.props;
    return (
      <div className={classes.container}>
        <div>
          <Typography variant="h4">Job Postings</Typography>
        </div>
        <div>
          <div style={{ display: 'flex', width: '100%' }}>
            <Button
              variant="contained"
              color="secondary"
              href={`${match.path}/new-posting`}
              className={classes.button}
              style={{ marginLeft: 'auto' }}
            >
              Add Job Posting
            </Button>
          </div>
          <JobTable match={match} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(JobPostingsMain);
