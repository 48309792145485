import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import ApplicationList from './ApplicationsList';
import SkeletonList from './SkeletonList';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

const styles = theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  textField: {
    margin: theme.spacing(1)
  }
});

const resetTimeout = (id, newId) => {
  clearTimeout(id);
  return newId;
};

export class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applicationSearchText: '',
      fetchingApplicationsIndex: true,
      applicationsList: null,
      selectedApplicationId: null,
      timeout: null
    };
    this.applicationsFullTextMap = {};
    this.applicationsFullTextKeysArray = [];
    this.applicationsIndex = [];
    this.resultsContainer = React.createRef();
  }

  componentDidMount() {
    this.fetchApplicationsIndex();
  }

  fetchApplicationsIndex = () => {
    this.setState({ fetchingApplicationsIndex: true });
    firebase
      .firestore()
      .collection('applicationsIndex')
      .where('archived', '==', false)
      .orderBy('createdAt', 'desc')
      .get()
      .then(snapshot => {
        const applicationsIndex = [];
        snapshot.forEach(doc => {
          const application = doc.data();
          application.fullName = `${application.firstName} ${application.lastName}`;
          application.fullText = `${application.fullName} ${application.position}`;
          applicationsIndex.push(application);
          this.applicationsFullTextMap[`${application.fullText.toLowerCase()}`] = application;
          this.applicationsFullTextKeysArray.push(application.fullText.toLowerCase());
        });
        this.applicationsIndex = applicationsIndex;
        this.setState({ fetchingApplicationsIndex: false, applicationsList: this.applicationsIndex });
      })
      .catch(error => {});
  };

  handleApplicationSearch = e => {
    const { timeout } = this.state;
    const target = e.target || {};
    const value = target.value || '';

    this.setState({
      applicationSearchText: value,
      timeout: resetTimeout(timeout, setTimeout(this.evaluateSearch, 300))
    });
  };

  evaluateSearch = () => {
    const { applicationSearchText } = this.state;

    if (!applicationSearchText) {
      this.setState({ applicationsList: this.applicationsIndex });
      return;
    }

    const keys = this.applicationsFullTextKeysArray;

    if (!keys.length) {
      return;
    }

    const searchTerm = applicationSearchText.toLowerCase();
    const query = searchTerm.split(' ');

    const results = [];

    keys.forEach(fullText => {
      let added = false;
      query.forEach(word => {
        if (fullText.indexOf(word) > -1 && !added) {
          added = true;
          results.push(this.applicationsFullTextMap[fullText]);
        }
      });
    });
    this.setState({ applicationsList: results });
  };

  handleClickApplicant = applicationId => {
    const { updateSelectedApplication } = this.props;
    this.setState({ selectedApplicationId: applicationId }, () => updateSelectedApplication(applicationId));
  };

  getResultsContainerHeight = () => {
    const resultsContainer = this.resultsContainer || {};
    const ref = resultsContainer.current || {};
    const height = ref.scrollHeight || 0;
    return height;
  };

  render() {
    const { classes } = this.props;
    const { fetchingApplicationsIndex, applicationsList, selectedApplicationId } = this.state;

    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: classes.drawer
        }}
        anchor="left"
      >
        <div className={classes.searchContainer}>
          <TextField
            id={`application-search`}
            label="Search Applicants"
            name="applicationSearchText"
            className={classes.textField}
            margin="dense"
            variant="outlined"
            value={this.state.applicationSearchText}
            onChange={this.handleApplicationSearch}
            fullWidth
          />
        </div>
        <Divider />
        <div style={{ height: '100%', width: '100%' }} ref={this.resultsContainer}>
          {fetchingApplicationsIndex ? (
            <SkeletonList />
          ) : (
            <ApplicationList
              list={applicationsList}
              height={this.getResultsContainerHeight()}
              handleClickApplicant={this.handleClickApplicant}
              selectedApplicationId={selectedApplicationId}
            />
          )}
        </div>
      </Drawer>
    );
  }
}

export default withStyles(styles)(Sidebar);
