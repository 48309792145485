import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MobileStep from './MobileStep';
import Hidden from '@material-ui/core/Hidden';
import { validate } from 'email-validator';
import phone from 'phone';

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'column'
  },
  buttonContainer: {
    marginTop: 'auto',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  headingContainer: {
    borderBottom: '1px solid grey',
    marginBottom: theme.spacing(2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  textField: {
    backgroundColor: '#fff',
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0
    }
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2)
  },
  sectionFieldsContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  }
});

export class ContactInfo extends Component {
  state = {
    emailAddress: '',
    confirmEmailAddress: '',
    emailAddressError: false,
    emailConfirmed: null,
    primaryPhoneNumber: '',
    secondaryPhoneNumber: '',
    primaryPhoneNumberError: false,
    secondaryPhoneNumberError: false,
    streetAddress: '',
    unitSuiteNumber: '',
    city: '',
    state: '',
    zipCode: '',
    isComplete: false
  };

  componentDidMount() {
    const { globalState } = this.props;

    window.scrollTo(0, 0);

    this.setState(
      {
        emailAddress: globalState.emailAddress || '',
        confirmEmailAddress: globalState.confirmEmailAddress || '',
        emailAddressError: globalState.emailAddressError || false,
        emailConfirmed: globalState.emailConfirmed || null,
        primaryPhoneNumber: globalState.primaryPhoneNumber || '',
        secondaryPhoneNumber: globalState.secondaryPhoneNumber || '',
        primaryPhoneNumberError: globalState.primaryPhoneNumberError || false,
        secondaryPhoneNumberError: globalState.secondaryPhoneNumberError || false,
        streetAddress: globalState.streetAddress || '',
        unitSuiteNumber: globalState.unitSuiteNumber || '',
        city: globalState.city || '',
        state: globalState.state || '',
        zipCode: globalState.zipCode || ''
      },
      this.processStep
    );
  }

  handleFieldChange = ({ target = {} }) => {
    const name = target.name;
    const value = target.value;

    this.setState({ [name]: value }, this.processStep);
  };

  processStep = () => {
    const { updateGlobalState } = this.props;
    const {
      emailAddress,
      confirmEmailAddress,
      emailAddressError,
      emailConfirmed,
      primaryPhoneNumber,
      secondaryPhoneNumber,
      primaryPhoneNumberError,
      secondaryPhoneNumberError,
      streetAddress,
      unitSuiteNumber,
      city,
      state,
      zipCode
    } = this.state;
    const isComplete =
      !!emailAddress &&
      !!confirmEmailAddress &&
      !emailAddressError &&
      !!emailConfirmed &&
      !!primaryPhoneNumber &&
      !primaryPhoneNumberError &&
      !!streetAddress &&
      !!city &&
      !!state &&
      !!zipCode;
    updateGlobalState({
      emailAddress,
      confirmEmailAddress,
      emailAddressError,
      primaryPhoneNumberError,
      secondaryPhoneNumberError,
      emailConfirmed,
      primaryPhoneNumber,
      secondaryPhoneNumber,
      streetAddress,
      unitSuiteNumber,
      city,
      state,
      zipCode
    });
    this.setState({ isComplete });
  };

  handleEmailChange = ({ target = {} }) => {
    const emailAddress = target.value || '';
    const emailIsValid = validate(emailAddress);
    this.setState({ emailAddress, emailAddressError: !emailIsValid }, () => this.handleConfirmEmailChange(this.state.confirmEmailAddress));
  };

  handleConfirmEmailChange = (confirmEmailAddress = '') => {
    let emailAddress = this.state.emailAddress || '';
    let isSame = !!emailAddress && !!confirmEmailAddress;
    if (!!isSame && emailAddress.toLowerCase() === confirmEmailAddress.toLowerCase()) {
      isSame = true;
    } else {
      isSame = false;
    }

    this.setState({ emailConfirmed: isSame, confirmEmailAddress }, this.processStep);
  };

  handlePhoneNumberChange = ({ target = {} }) => {
    const phoneNumber = target.value || '';
    const name = target.name;
    const errorName = name === 'primaryPhoneNumber' ? 'primaryPhoneNumberError' : 'secondaryPhoneNumberError';

    if (!name) {
      return;
    }

    // this actually returns an array, if array is empty its not valid
    // if array has length, we're good.
    const parsedNumber = phone(phoneNumber);
    const isValid = parsedNumber.length || phoneNumber === '';

    this.setState({ [name]: phoneNumber, [errorName]: !isValid }, this.processStep);
  };

  render() {
    const { handleBack, handleNext, classes } = this.props;
    const { isComplete } = this.state;
    return (
      <div className={classes.container}>
        <div className={classes.headingContainer}>
          <Typography variant="h5" gutterBottom color="primary">
            We will need your contact information
          </Typography>
        </div>
        <div className={classes.sectionContainer}>
          <Typography variant="h6" color="primary">
            Email Address*
          </Typography>
          <div className={classes.sectionFieldsContainer}>
            <TextField
              required
              id="email-input"
              label="Email address"
              className={classes.textField}
              type="email"
              name="emailAddress"
              autoComplete="email"
              margin="dense"
              variant="outlined"
              fullWidth
              value={this.state.emailAddress}
              onChange={this.handleEmailChange}
              error={this.state.emailAddressError}
              helperText={this.state.emailAddressError ? 'The email is badly formed' : null}
            />
            <TextField
              required
              id="confirm-email-input"
              label="Confirm email address"
              className={classes.textField}
              type="email"
              name="confirmEmailAddress"
              autoComplete="email"
              margin="dense"
              variant="outlined"
              fullWidth
              value={this.state.confirmEmailAddress}
              onChange={e => this.handleConfirmEmailChange(e.target.value)}
              error={this.state.emailConfirmed === false}
              helperText={this.state.emailConfirmed === false ? 'The email does not match' : null}
            />
          </div>
        </div>

        <div className={classes.sectionContainer}>
          <Typography variant="h6" color="primary">
            Your Phone Numbers*
          </Typography>
          <div className={classes.sectionFieldsContainer}>
            <TextField
              required
              id="primary-phone"
              label="Primary Phone Number"
              name="primaryPhoneNumber"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.primaryPhoneNumber}
              onChange={this.handlePhoneNumberChange}
              fullWidth
              error={this.state.primaryPhoneNumberError}
              placeholder="555-555-555"
              helperText={this.state.primaryPhoneNumberError ? 'Please enter a valid phone number: 555-555-5555' : ''}
            />

            <TextField
              id="secondary-phone"
              label="Secondary Phone Number"
              name="secondaryPhoneNumber"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.secondaryPhoneNumber}
              onChange={this.handlePhoneNumberChange}
              fullWidth
              error={this.state.secondaryPhoneNumberError}
              placeholder="555-555-555"
              helperText={this.state.secondaryPhoneNumberError ? 'Please enter a valid phone number: 555-555-5555' : ''}
            />
          </div>
        </div>

        <div className={classes.sectionContainer}>
          <Typography variant="h6" color="primary">
            Address information*
          </Typography>
          <div className={classes.sectionFieldsContainer}>
            <TextField
              required
              id="street-address"
              label="Street Address"
              name="streetAddress"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              fullWidth
              value={this.state.streetAddress}
              onChange={this.handleFieldChange}
              error={this.state.streetAddressError}
              helperText={this.state.streetAddressError ? 'This field is required' : null}
            />
          </div>
          <div className={classes.sectionFieldsContainer}>
            <TextField
              id="unit-suite-number"
              label="Unit / Suite Number"
              name="unitSuiteNumber"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              fullWidth
              value={this.state.unitSuiteNumber}
              onChange={this.handleFieldChange}
            />
          </div>
          <div className={classes.sectionFieldsContainer}>
            <TextField
              required
              id="city"
              label="City"
              name="city"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              fullWidth
              value={this.state.city}
              onChange={this.handleFieldChange}
              error={this.state.cityError}
              helperText={this.state.cityError ? 'This field is required' : null}
            />
            <TextField
              required
              id="state"
              label="State"
              name="state"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              fullWidth
              value={this.state.state}
              onChange={this.handleFieldChange}
              error={this.state.stateError}
              helperText={this.state.stateError ? 'This field is required' : null}
            />
            <TextField
              required
              id="zip-code"
              label="Zip Code"
              name="zipCode"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              fullWidth
              value={this.state.zipCode}
              onChange={this.handleFieldChange}
              error={this.state.zipCodeError}
              helperText={this.state.zipCodeError ? 'This field is required' : null}
            />
          </div>
        </div>

        <div className={classes.buttonContainer}>
          <Hidden smDown>
            <Button onClick={handleBack} className={classes.backButton}>
              Back
            </Button>
            <Button disabled={!isComplete} variant="contained" color="secondary" onClick={handleNext}>
              Next
            </Button>
          </Hidden>
          <MobileStep
            backDisabled={false}
            nextDisabled={!isComplete}
            steps={this.props.steps}
            activeStep={this.props.activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ContactInfo);
