export function map(applicationData) {
  const get = property => {
    const thisData = applicationData[property] || '';
    return thisData;
  };

  return _mapData(get);
}

function _mapData(get) {
  return {
    tables: [
      {
        title: 'Name',
        rows: [
          {
            fields: [
              {
                name: 'firstName',
                label: 'First Name',
                value: get('firstName')
              },
              {
                name: 'middleInitial',
                label: 'M.I.',
                value: get('middleInitial')
              },
              {
                name: 'lastName',
                label: 'Last Name',
                value: get('lastName')
              },
              {
                name: 'suffix',
                label: 'Suffix',
                value: get('suffix')
              }
            ]
          }
        ]
      },
      {
        title: 'Position',
        rows: [
          {
            fields: [
              {
                name: 'position',
                label: 'Position',
                value: get('position')
              }
            ]
          }
        ]
      },
      {
        title: 'Reference',
        rows: [
          {
            fields: [
              {
                name: 'referredBy',
                label: 'How did you hear about de foxx?',
                value: get('referredBy')
              },
              {
                name: 'referredByName',
                label: 'Referred By',
                value: get('referredByName')
              }
            ]
          }
        ]
      },
      {
        title: 'Contact',
        rows: [
          {
            fields: [
              {
                name: 'emailAddress',
                label: 'Email Address',
                value: get('emailAddress')
              },
              {
                name: 'primaryPhoneNumber',
                label: 'Primary Phone Number',
                value: get('primaryPhoneNumber')
              },
              {
                name: 'secondaryPhoneNumber',
                label: 'Secondary Phone Number',
                value: get('secondaryPhoneNumber')
              }
            ]
          },
          {
            fields: [
              {
                name: 'streetAddress',
                label: 'Street Address',
                value: get('streetAddress')
              },
              {
                name: 'unitSuiteNumber',
                label: 'Unit / Suite Number',
                value: get('unitSuiteNumber')
              }
            ]
          },
          {
            fields: [
              {
                name: 'city',
                label: 'City',
                value: get('city')
              },
              {
                name: 'state',
                label: 'State',
                value: get('state')
              },
              {
                name: 'zipCode',
                label: 'Zip Code',
                value: get('zipCode')
              }
            ]
          }
        ]
      },
      {
        title: 'Work Experience',
        rows: [
          {
            description: 'Company #1',
            fields: [
              {
                name: 'companyName1',
                label: 'Company Name',
                value: get('companyName1')
              },
              {
                name: 'companyCityState1',
                label: 'City & State',
                value: get('companyCityState1')
              },
              {
                name: 'companyTitle1',
                label: 'Title',
                value: get('companyTitle1')
              }
            ]
          },
          {
            fields: [
              {
                name: 'companyStartDate1',
                label: 'Start Date',
                value: get('companyStartDate1')
              },
              {
                name: 'companyEndDate1',
                label: 'End Date',
                value: get('companyEndDate1')
              }
            ]
          },
          {
            fields: [
              {
                name: 'companyContactTitle1',
                label: 'Contact Name & Title',
                value: get('companyContactTitle1')
              },
              {
                name: 'companyPhoneNumber1',
                label: 'Phone Number',
                value: get('companyPhoneNumber1')
              },
              {
                name: 'companyEmailAddress1',
                label: 'Email Address',
                value: get('companyEmailAddress1')
              },
              {
                name: 'companyCanContact1',
                label: 'Can Contact?',
                value: get('companyCanContact1')
              }
            ]
          },
          {
            fields: [
              {
                name: 'companyReasonLeaving1',
                label: 'Reason For Leaving',
                value: get('companyReasonLeaving1')
              }
            ]
          },

          {
            description: 'Company #2',
            fields: [
              {
                name: 'companyName2',
                label: 'Company Name',
                value: get('companyName2')
              },
              {
                name: 'companyCityState2',
                label: 'City & State',
                value: get('companyCityState2')
              },
              {
                name: 'companyTitle2',
                label: 'Title',
                value: get('companyTitle2')
              }
            ]
          },
          {
            fields: [
              {
                name: 'companyStartDate2',
                label: 'Start Date',
                value: get('companyStartDate2')
              },
              {
                name: 'companyEndDate2',
                label: 'End Date',
                value: get('companyEndDate2')
              }
            ]
          },
          {
            fields: [
              {
                name: 'companyContactTitle2',
                label: 'Contact Name & Title',
                value: get('companyContactTitle2')
              },
              {
                name: 'companyPhoneNumber2',
                label: 'Phone Number',
                value: get('companyPhoneNumber2')
              },
              {
                name: 'companyEmailAddress2',
                label: 'Email Address',
                value: get('companyEmailAddress2')
              },
              {
                name: 'companyCanContact2',
                label: 'Can Contact?',
                value: get('companyCanContact2')
              }
            ]
          },
          {
            fields: [
              {
                name: 'companyReasonLeaving2',
                label: 'Reason For Leaving',
                value: get('companyReasonLeaving2')
              }
            ]
          },

          {
            description: 'Company #3',
            fields: [
              {
                name: 'companyName3',
                label: 'Company Name',
                value: get('companyName3')
              },
              {
                name: 'companyCityState3',
                label: 'City & State',
                value: get('companyCityState3')
              },
              {
                name: 'companyTitle3',
                label: 'Title',
                value: get('companyTitle3')
              }
            ]
          },
          {
            fields: [
              {
                name: 'companyStartDate3',
                label: 'Start Date',
                value: get('companyStartDate3')
              },
              {
                name: 'companyEndDate3',
                label: 'End Date',
                value: get('companyEndDate3')
              }
            ]
          },
          {
            fields: [
              {
                name: 'companyContactTitle3',
                label: 'Contact Name & Title',
                value: get('companyContactTitle3')
              },
              {
                name: 'companyPhoneNumber3',
                label: 'Phone Number',
                value: get('companyPhoneNumber3')
              },
              {
                name: 'companyEmailAddress3',
                label: 'Email Address',
                value: get('companyEmailAddress3')
              },
              {
                name: 'companyCanContact3',
                label: 'Can Contact?',
                value: get('companyCanContact3')
              }
            ]
          },
          {
            fields: [
              {
                name: 'companyReasonLeaving3',
                label: 'Reason For Leaving',
                value: get('companyReasonLeaving3')
              }
            ]
          }
        ]
      },
      {
        title: 'Education',
        rows: [
          {
            description: 'School #1',
            fields: [
              {
                name: 'schoolName1',
                label: 'School Name',
                value: get('schoolName1')
              },
              {
                name: 'schoolCityState1',
                label: 'City / State',
                value: get('schoolCityState1')
              },
              {
                name: 'schoolLevel1',
                label: 'Level',
                value: get('schoolLevel1')
              }
            ]
          },
          {
            fields: [
              {
                name: 'schoolMajor1',
                label: 'Major',
                value: get('schoolMajor1')
              },
              {
                name: 'schoolMinor1',
                label: 'Minor',
                value: get('schoolMinor1')
              },
              {
                name: 'schoolGpa1',
                label: 'GPA',
                value: get('schoolGpa1')
              }
            ]
          },
          {
            fields: [
              {
                name: 'schoolCompleted1',
                label: 'Completed',
                value: get('schoolCompleted1')
              },
              {
                name: 'schoolStartDate1',
                label: 'Start Date',
                value: get('schoolStartDate1')
              },
              {
                name: 'schoolEndDate1',
                label: 'End Date',
                value: get('schoolEndDate1')
              }
            ]
          },

          {
            description: 'School #2',
            fields: [
              {
                name: 'schoolName2',
                label: 'School Name',
                value: get('schoolName2')
              },
              {
                name: 'schoolCityState2',
                label: 'City / State',
                value: get('schoolCityState2')
              },
              {
                name: 'schoolLevel2',
                label: 'Level',
                value: get('schoolLevel2')
              }
            ]
          },
          {
            fields: [
              {
                name: 'schoolMajor2',
                label: 'Major',
                value: get('schoolMajor2')
              },
              {
                name: 'schoolMinor2',
                label: 'Minor',
                value: get('schoolMinor2')
              },
              {
                name: 'schoolGpa2',
                label: 'GPA',
                value: get('schoolGpa2')
              }
            ]
          },
          {
            fields: [
              {
                name: 'schoolCompleted2',
                label: 'Completed',
                value: get('schoolCompleted2')
              },
              {
                name: 'schoolStartDate2',
                label: 'Start Date',
                value: get('schoolStartDate2')
              },
              {
                name: 'schoolEndDate2',
                label: 'End Date',
                value: get('schoolEndDate2')
              }
            ]
          },
          {
            description: 'Certificates',
            fields: [
              {
                name: 'schoolCerts1',
                label: 'Certificate #1',
                value: get('schoolCerts1')
              },
              {
                name: 'schoolCerts2',
                label: 'Certificate #2',
                value: get('schoolCerts2')
              },
              {
                name: 'schoolCerts3',
                label: 'Certificate #3',
                value: get('schoolCerts3')
              },
              {
                name: 'schoolCerts4',
                label: 'Certificate #4',
                value: get('schoolCerts4')
              }
            ]
          }
        ]
      },
      {
        title: 'Background',
        rows: [
          {
            fields: [
              {
                name: 'backgroundBeenConvictedFelony',
                label: 'Convicted of Felony',
                value: get('backgroundBeenConvictedFelony')
              },
              {
                name: 'backgroundConvictionFelonyDetails',
                label: 'Felony Details',
                value: get('backgroundConvictionFelonyDetails')
              }
            ]
          },
          {
            fields: [
              {
                name: 'backgroundBeenConvictedMisdemeanor',
                label: 'Convicted of Misdemeanor',
                value: get('backgroundBeenConvictedMisdemeanor')
              },
              {
                name: 'backgroundConvictionMisdemeanorDetails',
                label: 'Misdemeanor Details',
                value: get('backgroundConvictionMisdemeanorDetails')
              }
            ]
          },
          {
            fields: [
              {
                name: 'backgroundOutstandingLitigation',
                label: 'Involved In Any Outstanding Litigation',
                value: get('backgroundOutstandingLitigation')
              }
            ]
          }
        ]
      },
      {
        title: 'General Info',
        rows: [
          {
            fields: [
              {
                name: 'generalLegallyWorkInUs',
                label: 'Can Legally Work In U.S.',
                value: get('generalLegallyWorkInUs')
              },
              {
                name: 'generalAtLeast18',
                label: 'At Least 18',
                value: get('generalAtLeast18')
              }
            ]
          },
          {
            fields: [
              {
                name: 'generalWillingToTravel',
                label: 'Willing To Travel',
                value: get('generalWillingToTravel')
              },
              {
                name: 'generalWillingToTravelDetails',
                label: 'Willng To Travel Details',
                value: get('generalWillingToTravelDetails')
              }
            ]
          },
          {
            fields: [
              {
                name: 'generalTargetSalary',
                label: 'Target Salary / Hourly',
                value: get('generalTargetSalary')
              },
              {
                name: 'generalAvailableStartDate',
                label: 'Available Start Date',
                value: get('generalAvailableStartDate')
              },
              {
                name: 'generalRequireSponsorship',
                label: 'Will Require Sponsorship',
                value: get('generalRequireSponsorship')
              }
            ]
          }
        ]
      },
      {
        title: 'Disclosures & Signature',
        rows: [
          {
            fields: [
              {
                name: 'initials',
                label: 'Applicant Initials',
                value: get('initials')
              },
              {
                name: 'signature',
                label: 'Applicant Signature',
                value: get('signature')
              },
              {
                name: 'applicationDate',
                label: 'Application Date',
                value: get('applicationDate')
              }
            ]
          }
        ]
      }
    ]
  };
}

export default map;
