import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const styles = theme => ({
  rootLink: {
    width: '100%',
    textDecoration: 'none',
    color: 'unset',
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    transition: '0.5s',
    '&:hover': {
      background: theme.palette.secondary.main,
      opacity: 0.7
    },
    '&:last-child': {
      borderBottom: `1px solid ${theme.palette.secondary.main}`
    }
  },
  root: {
    display: 'flex',
    // width: '100%',
    height: '116px',
    paddingLeft: theme.spacing(3),
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      right: `${30 + theme.spacing(3)}px`,
      top: 45,
      border: `1px solid ${theme.palette.primary.main}`,
      borderWidth: '0 0 2px 2px',
      width: '30px',
      height: '30px',
      transform: 'rotate(-135deg)',
      [theme.breakpoints.down('sm')]: {
        right: theme.spacing(2)
      }
    }
  },
  listing: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  jobDetails: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  jobDetailsItem: {
    display: 'list-item',
    listStyleType: 'disc',
    listStylePosition: 'inside',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      listStyleType: 'none',
      marginLeft: 0
    }
  }
});

export class JobListingResult extends Component {
  state = { selectedPosition: '' };

  render() {
    const { match, posting, classes } = this.props;
    return (
      <Link to={`${match.url}/${posting.id}`} className={classes.rootLink}>
        {/* <a href="http://defoxx.com" className={classes.rootLink}> */}
        <div className={classes.root}>
          <div className={classes.listing}>
            <div className={classes.jobTitle}>
              <Typography variant="h6" color="primary" style={{ fontWeight: 600 }}>
                {posting.name}
              </Typography>
            </div>
            <div className={classes.jobDetails}>
              <Typography variant="body1" color="primary">
                {posting.company}
              </Typography>
              <Typography variant="body1" color="primary" className={classes.jobDetailsItem}>
                {posting.location}
              </Typography>
            </div>
          </div>
        </div>
        {/* </a> */}
      </Link>
    );
  }
}

export default withStyles(styles)(JobListingResult);
