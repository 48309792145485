import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import ApplicationDisplay from '../ApplicationDisplay';

const styles = theme => ({
  root: {
    height: '100%'
  }
});

export class MainContainer extends Component {
  render() {
    const { classes, selectedApplicationId, handleArchiveApplication } = this.props;
    return (
      <div className={classes.root}>
        <ApplicationDisplay selectedApplicationId={selectedApplicationId} handleArchiveApplication={handleArchiveApplication} />
      </div>
    );
  }
}

export default withStyles(styles)(MainContainer);
