import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { validate } from 'email-validator';
import * as firebase from 'firebase/app';
import 'firebase/auth';

const styles = theme => ({
  root: {
    height: '100%',
    paddingTop: '64px'
  },
  headerRoot: {
    flexGrow: 1
  },
  appBar: {
    boxShadow: 'none'
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  container: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#fafafa'
  },
  authForm: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '31.25rem'
  },
  loginLabel: {
    padding: theme.spacing(3)
  },
  textField: {
    margin: theme.spacing(1)
  },
  button: {
    padding: theme.spacing(1, 5),
    margin: theme.spacing(2, 0, 2, 0)
  },
  loginToggleLabel: {
    cursor: 'pointer',
    textDecoration: 'underline'
  }
});

export class Login extends Component {
  state = {
    isLogin: true,
    emailAddress: '',
    password: '',
    confirmPassword: '',
    emailAddressError: false,
    passNoMatchError: false
  };

  handleUpdateEmail = ({ target = {} }) => {
    const emailAddress = target.value || '';
    const emailIsValid = validate(emailAddress);

    this.setState({ emailAddress, emailAddressError: !emailIsValid });
  };

  handleUpdatePassword = ({ target = {} }) => {
    const { isLogin, confirmPassword } = this.state;
    const password = target.value || '';
    const passwordIsValid = isLogin ? true : password === confirmPassword;
    this.setState({ password, passNoMatchError: !passwordIsValid });
  };

  handleUpdateConfirmPassword = ({ target = {} }) => {
    const { password } = this.state;
    const confirmPassword = target.value || '';
    this.setState({ confirmPassword, passNoMatchError: password !== confirmPassword });
  };

  handleLoginClicked = () => {
    const { emailAddress, password, passNoMatchError, emailAddressError } = this.state;

    if (emailAddressError || passNoMatchError) {
      return;
    }
    firebase
      .auth()
      .signInWithEmailAndPassword(emailAddress, password)
      .catch(error => {
        var errorCode = error.code;
        var errorMessage = error.message;
        this.setState({ authError: `${errorCode}: ${errorMessage}` });
      });
  };

  handleSignUpClicked = () => {
    const { emailAddress, password, passNoMatchError, emailAddressError } = this.state;

    if (emailAddressError || passNoMatchError) {
      return;
    }

    firebase
      .auth()
      .createUserWithEmailAndPassword(emailAddress, password)
      .catch(error => {
        var errorCode = error.code;
        var errorMessage = error.message;
        this.setState({ authError: `${errorCode}: ${errorMessage}` });
      });
  };

  handleToggleLoginOrCreate = () => {
    const { isLogin } = this.state;
    this.setState({ isLogin: !isLogin });
  };

  render() {
    const { classes } = this.props;
    const { isLogin, emailAddressError, passNoMatchError, authError } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.headerRoot}>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                d.e. Foxx
              </Typography>
            </Toolbar>
          </AppBar>
        </div>
        <div className={classes.container}>
          <Paper className={classes.authForm}>
            <Typography variant="h5" component="h3" className={classes.loginLabel}>
              {isLogin ? 'Login' : 'Sign Up'}
            </Typography>

            {authError && (
              <Typography variant="h6" color="error">
                {authError}
              </Typography>
            )}

            <TextField
              error={emailAddressError}
              id="email-address"
              label="d.e. Foxx Email Address"
              name="emailAddress"
              className={classes.textField}
              fullWidth
              variant="outlined"
              value={this.state.emailAddress}
              onChange={this.handleUpdateEmail}
              helperText={emailAddressError ? 'Email is not valid' : ''}
            />
            <TextField
              error={passNoMatchError}
              id="password"
              label="password"
              name="password"
              className={classes.textField}
              fullWidth
              variant="outlined"
              type="password"
              value={this.state.password}
              onChange={this.handleUpdatePassword}
              helperText={passNoMatchError ? "Passwords don't match" : ''}
            />

            {!isLogin && (
              <TextField
                error={passNoMatchError}
                id="confirm-password"
                label="confirm password"
                name="confirmPassword"
                className={classes.textField}
                fullWidth
                variant="outlined"
                type="password"
                value={this.state.confirmPassword}
                onChange={this.handleUpdateConfirmPassword}
                helperText={passNoMatchError ? "Passwords don't match" : ''}
              />
            )}
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={isLogin ? this.handleLoginClicked : this.handleSignUpClicked}
            >
              {isLogin ? 'Login' : 'Sign Up'}
            </Button>

            <Typography variant="caption" className={classes.loginToggleLabel} onClick={this.handleToggleLoginOrCreate}>
              {isLogin ? 'Sign Up' : 'Login'}
            </Typography>
          </Paper>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Login);
