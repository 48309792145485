import React, { Component } from 'react';
import { FixedSizeList } from 'react-window';
import ApplicationsListItem from './ApplicationsListItem';

export class ApplicationsList extends Component {
  itemHeight = 56;

  render() {
    const { list, height, handleClickApplicant, selectedApplicationId } = this.props;
    const data = { list, handleClickApplicant, selectedApplicationId };

    if (!list || !list.length) {
      return null;
    }

    return (
      <FixedSizeList height={height} itemCount={list.length} itemSize={this.itemHeight} width="100%" itemData={data} style={{ overflowX: 'hidden' }}>
        {ApplicationsListItem}
      </FixedSizeList>
    );
  }
}

export default ApplicationsList;
