import { createMuiTheme } from '@material-ui/core/styles';

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#58595b'
    },
    secondary: {
      main: '#ffbd00'
    },
    grey: {
      main: '#f3f3f4'
    }
  },
  container: {
    width: {
      default: '1170px',
      md: '970px'
    }
  }
});

export default theme;
