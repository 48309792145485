import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import DeleteIcon from '@material-ui/icons/Delete';
// import IconButton from '@material-ui/core/IconButton';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  },
  table: {
    minWidth: 950,
    width: 950
  },
  row: {
    background: '#fff'
  }
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  // background: isDragging ? 'lightgreen' : 'grey',
  textDecoration: 'none',
  width: 950,
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'grey',
  // padding: grid,
  width: 950
});

export class JobTable extends Component {
  state = {
    jobPostings: [],
    fetchingJobPostingsIndex: true
  };

  componentDidMount() {
    this.fetchJobPostingsIndex();
  }

  fetchJobPostingsIndex = () => {
    this.setState({ fetchingJobPostingsIndex: true });
    firebase
      .firestore()
      .collection('jobPostings')
      .doc('postings')
      .collection('postingsIndex')
      .orderBy('sortOrder', 'asc')
      .get()
      .then(snapshot => {
        const jobPostingsIndex = [];
        snapshot.forEach(doc => {
          const jobPosting = doc.data();
          jobPosting.id = doc.id;
          jobPostingsIndex.push(jobPosting);
        });
        this.setState({ jobPostings: jobPostingsIndex, fetchingJobPostingsIndex: false });
      })
      .catch(error => {});
  };

  updateJobPostingsSortOrder = jobPostings => {
    const db = firebase.firestore();
    const batch = db.batch();
    const postingsIndexPath = db
      .collection('jobPostings')
      .doc('postings')
      .collection('postingsIndex');

    jobPostings.forEach((posting, index) => {
      batch.set(postingsIndexPath.doc(posting.id), { sortOrder: index }, { merge: true });
    });

    batch.commit();
  };

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const jobPostings = reorder(this.state.jobPostings, result.source.index, result.destination.index);

    this.setState(
      {
        jobPostings
      },
      () => this.updateJobPostingsSortOrder(jobPostings)
    );
  };

  render() {
    const { match, classes } = this.props;
    return (
      <div>
        Click a job posting to edit. Click and drag a posting to re-order.
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Position</TableCell>
                <TableCell align="right">Brand/Company</TableCell>
                <TableCell align="right">Location</TableCell>
                <TableCell align="right">Is Active</TableCell>
                {/* <TableCell align="right">Delete Posting</TableCell> */}
              </TableRow>
            </TableHead>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <TableBody {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                    {this.state.jobPostings.map((posting, index) => (
                      <Draggable key={posting.id} draggableId={posting.id} index={index}>
                        {(provided, snapshot) => (
                          <TableRow
                            className={classes.row}
                            component={Link}
                            to={`${match.path}/edit-posting/${posting.id}`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <TableCell component="th" scope="row">
                              {posting.name}
                            </TableCell>
                            <TableCell align="right">{posting.company}</TableCell>
                            <TableCell align="right">{posting.location}</TableCell>
                            <TableCell align="right">{posting.active ? 'Yes' : 'No'}</TableCell>
                            {/* <TableCell align="right">
                              <IconButton aria-label="delete" className={classes.margin} onClick={() => this.handleDelete(posting.id)}>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell> */}
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(JobTable);
