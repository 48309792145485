import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Skeleton from '@material-ui/lab/Skeleton';

const styles = theme => ({
  listItemSkeleton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
});

export class SkeletonList extends Component {
  render() {
    const { classes } = this.props;
    return (
      <List component="nav" aria-label="applicants">
        {[...Array(5)].map((e, i) => {
          return (
            <ListItem key={i} className={classes.listItemSkeleton}>
              <Skeleton height={6} width="60%" />
              <Skeleton height={6} width="80%" />
            </ListItem>
          );
        })}
      </List>
    );
  }
}

export default withStyles(styles)(SkeletonList);
