import React, { Component } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { withStyles } from '@material-ui/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Name from './Name';
import ContactInfo from './ContactInfo';
import WorkExperience from './WorkExperience';
import Education from './Education';
import GeneralQuestions from './GeneralQuestions';
import Resume from './Resume';
import logo from '../static/images/logo.png';

const styles = theme => ({
  root: {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '90%',
    flex: '0 0 auto'
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center'
  },
  stepperIcon: {
    '&.MuiStepIcon-active': {
      color: theme.palette.secondary.main,
      transform: 'scaleX(1.5) scaleY(1.5)',
      '& .MuiStepIcon-text': {
        fill: theme.palette.primary.main
      }
    }
  },
  logoContainer: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0.5)
  }
});

function getSteps() {
  return ['Your Name', 'Contact Information', 'Work Experience', 'Education & Certifications', 'General Questions', 'Upload Your Resume'];
}

export class JobApplication extends Component {
  state = {
    activeStep: 0,
    isComplete: false
  };

  handleNext = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep + 1 });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep - 1 });
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  updateGlobalState = updatedState => {
    this.setState(updatedState);
  };

  getStepContent = stepIndex => {
    const { activeStep } = this.state;
    const props = {
      globalState: this.state,
      updateGlobalState: this.updateGlobalState,
      activeStep,
      steps: getSteps(),
      handleBack: this.handleBack,
      handleNext: this.handleNext,
      handleReset: this.handleReset
    };
    switch (stepIndex) {
      case 0:
        return <Name {...props} location={this.props.location} />;
      case 1:
        return <ContactInfo {...props} />;
      case 2:
        return <WorkExperience {...props} />;
      case 3:
        return <Education {...props} />;
      case 4:
        return <GeneralQuestions {...props} />;
      case 5:
        return <Resume {...props} submitApplication={this.submitApplication} />;
      default:
        return "oops how'd you get here?";
    }
  };

  submitApplication = () => {
    const application = {
      ...this.state,
      archived: false,
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    };
    const db = firebase.firestore();

    const batch = db.batch();
    const appRef = db.collection('applications').doc();
    const appIndexRef = db.collection('applicationsIndex').doc(appRef.id);
    const appIndex = {
      firstName: application.firstName,
      lastName: application.lastName,
      emailAddress: application.emailAddress,
      position: application.position,
      id: appRef.id,
      createdAt: application.createdAt,
      archived: false
    };

    batch.set(appRef, application);
    batch.set(appIndexRef, appIndex);

    batch
      .commit()
      .then((a, b, c) => {
        this.setState({ success: true }, this.handleNext);
      })
      .catch(error => {
        this.setState({ error: true }, this.handleNext);
      });
  };

  shouldComponentUpdate(nextProps, nextState) {
    const currectActiveStep = this.state.activeStep;
    const nextActiveStep = nextState.activeStep;

    return currectActiveStep !== nextActiveStep;
  }

  render() {
    const { classes } = this.props;
    const { activeStep } = this.state;
    const steps = getSteps();
    return (
      <div className={classes.root}>
        <div className={classes.logoContainer}>
          <img src={logo} alt="d.e. Foxx logo" />
        </div>
        <Hidden smDown>
          <Stepper activeStep={activeStep} alternativeLabel style={{ width: '100%' }}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel StepIconProps={{ classes: { root: classes.stepperIcon } }}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Hidden>
        <div className={classes.root}>
          {activeStep === steps.length ? (
            <div style={{ paddingTop: '100px' }}>
              {this.state.success ? (
                <Typography variant="h6" color="primary">
                  Your application has been successfully submitted. Thank you.
                </Typography>
              ) : (
                <Typography variant="h6" color="primary">
                  There was an error submitting your application. Please contact D.E. Foxx.
                </Typography>
              )}
            </div>
          ) : (
            <div style={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'column' }}>{this.getStepContent(activeStep)}</div>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(JobApplication);
