import React, { Component } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import theme from './theme';
import JobApplication from './JobApplication';
import Careers from './Careers';
import AdminDashboard from './AdminDashboard';
import JobPostings from './AdminDashboard/JobPostings';
import Login from './Login';

const firebaseConfig = {
  apiKey: 'AIzaSyAwACBI39Wv6V0sPV0hJFawBNnj1fcxOUE',
  authDomain: 'defoxx-70761.firebaseapp.com',
  databaseURL: 'https://defoxx-70761.firebaseio.com',
  projectId: 'defoxx-70761',
  storageBucket: 'defoxx-70761.appspot.com',
  messagingSenderId: '469255593681',
  appId: '1:469255593681:web:6388c6e119aae6ba'
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

function PrivateRoute({ component: Component, ...rest }) {
  const { user } = rest;
  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

export class App extends Component {
  state = {
    user: null,
    userInitialized: false
  };

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({ user, userInitialized: true });
      } else {
        // No user is signed in.
        this.setState({ user: null, userInitialized: true });
      }
    });
  }

  render() {
    const { user, userInitialized } = this.state;

    if (!userInitialized) {
      return null;
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              <Route path="/application" component={JobApplication} />
              <Route path="/careers" component={Careers} />
              <PrivateRoute path="/dashboard" user={user} component={AdminDashboard} />
              <PrivateRoute path="/job-postings" user={user} component={JobPostings} />
              <Route path="/login" render={() => (user ? <Redirect to="/dashboard" /> : <Login />)} />
              <Redirect to="/application" />
            </Switch>
          </Router>
        </ThemeProvider>
      </div>
    );
  }
}

export default App;
