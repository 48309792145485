import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'column'
  },
  buttonContainer: {
    marginTop: 'auto',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  headingContainer: {
    borderBottom: '1px solid grey',
    marginBottom: theme.spacing(2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  textField: {
    backgroundColor: '#fff',
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0
    }
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2)
  },
  sectionFieldsContainer: {
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  }
});

const yesNoOptions = [
  {
    value: '',
    label: ''
  },
  {
    value: 'Yes',
    label: 'Yes'
  },
  {
    value: 'No',
    label: 'No'
  },
  {
    value: 'Later',
    label: 'Later'
  }
];

export class Company extends Component {
  state = {};

  getStatePropertyNames = () => {
    const { index } = this.props;
    return {
      companyName: `companyName${index}`,
      companyCityState: `companyCityState${index}`,
      companyTitle: `companyTitle${index}`,
      companyStartDate: `companyStartDate${index}`,
      companyEndDate: `companyEndDate${index}`,
      companyContactTitle: `companyContactTitle${index}`,
      companyPhoneNumber: `companyPhoneNumber${index}`,
      companyEmailAddress: `companyEmailAddress${index}`,
      companyCanContact: `companyCanContact${index}`,
      companyReasonLeaving: `companyReasonLeaving${index}`
    };
  };

  componentDidMount() {
    const { globalState } = this.props;

    const {
      companyName,
      companyCityState,
      companyTitle,
      companyStartDate,
      companyEndDate,
      companyContactTitle,
      companyPhoneNumber,
      companyEmailAddress,
      companyCanContact,
      companyReasonLeaving
    } = this.getStatePropertyNames();

    this.setState({
      [companyName]: globalState[companyName] || '',
      [companyCityState]: globalState[companyCityState] || '',
      [companyTitle]: globalState[companyTitle] || '',
      [companyStartDate]: globalState[companyStartDate] || '',
      [companyEndDate]: globalState[companyEndDate] || '',
      [companyContactTitle]: globalState[companyContactTitle] || '',
      [companyPhoneNumber]: globalState[companyPhoneNumber] || '',
      [companyEmailAddress]: globalState[companyEmailAddress] || '',
      [companyCanContact]: globalState[companyCanContact] || '',
      [companyReasonLeaving]: globalState[companyReasonLeaving] || ''
    });
  }

  handleFieldChange = ({ target = {} }) => {
    const name = target.name;
    const value = target.value;

    this.setState({ [name]: value }, this.processStep);
  };

  processStep = () => {
    const { updateGlobalState } = this.props;
    const {
      companyName,
      companyCityState,
      companyTitle,
      companyStartDate,
      companyEndDate,
      companyContactTitle,
      companyPhoneNumber,
      companyEmailAddress,
      companyCanContact,
      companyReasonLeaving
    } = this.getStatePropertyNames();

    updateGlobalState({
      [companyName]: this.state[companyName],
      [companyCityState]: this.state[companyCityState],
      [companyTitle]: this.state[companyTitle],
      [companyStartDate]: this.state[companyStartDate],
      [companyEndDate]: this.state[companyEndDate],
      [companyContactTitle]: this.state[companyContactTitle],
      [companyPhoneNumber]: this.state[companyPhoneNumber],
      [companyEmailAddress]: this.state[companyEmailAddress],
      [companyCanContact]: this.state[companyCanContact],
      [companyReasonLeaving]: this.state[companyReasonLeaving]
    });
  };

  render() {
    const { index, classes } = this.props;
    const {
      companyName,
      companyCityState,
      companyTitle,
      companyStartDate,
      companyEndDate,
      companyContactTitle,
      companyPhoneNumber,
      companyEmailAddress,
      companyCanContact,
      companyReasonLeaving
    } = this.getStatePropertyNames();
    const companyNameFromState = this.state[companyName];

    if (companyNameFromState === undefined) {
      return null;
    }

    return (
      <Fragment>
        <div className={classes.sectionContainer}>
          <Typography variant="h6" color="primary">
            {`Company #${index}`}
          </Typography>
          <div className={classes.sectionFieldsContainer}>
            <TextField
              id={`company-name-${index}`}
              label="Company Name"
              name={companyName}
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={companyNameFromState}
              onChange={this.handleFieldChange}
              fullWidth
            />
            <TextField
              id={`company-city-state-${index}`}
              label="Company City and State"
              name={companyCityState}
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state[companyCityState]}
              onChange={this.handleFieldChange}
              fullWidth
            />
            <TextField
              id={`company-title-${index}`}
              label="Your Title"
              name={companyTitle}
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state[companyTitle]}
              onChange={this.handleFieldChange}
              fullWidth
            />
          </div>

          <div className={classes.sectionFieldsContainer}>
            <TextField
              id={`company-start-date-${index}`}
              label="Start Date: MM/DD/YYYY"
              placeholder="MM/DD/YYYY"
              name={companyStartDate}
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state[companyStartDate]}
              onChange={this.handleFieldChange}
              fullWidth
            />
            <TextField
              id={`company-end-date-${index}`}
              label="End Date: MM/DD/YYYY"
              placeholder="MM/DD/YYYY"
              name={companyEndDate}
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state[companyEndDate]}
              onChange={this.handleFieldChange}
              fullWidth
            />
            <TextField
              id={`company-contact-title-${index}`}
              label="Contact Person / Title"
              name={companyContactTitle}
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state[companyContactTitle]}
              onChange={this.handleFieldChange}
              fullWidth
            />
          </div>

          <div className={classes.sectionFieldsContainer}>
            <TextField
              id={`company-phone-number-${index}`}
              label="Best Phone Number"
              name={companyPhoneNumber}
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state[companyPhoneNumber]}
              onChange={this.handleFieldChange}
              placeholder="555-555-5555"
              fullWidth
            />
            <TextField
              id={`company-email-address-${index}`}
              label="Email Address"
              name={companyEmailAddress}
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state[companyEmailAddress]}
              onChange={this.handleFieldChange}
              fullWidth
            />

            <TextField
              id={`company-can-contact-${index}`}
              select
              name={companyCanContact}
              label="May we contact them?"
              className={classes.textField}
              value={this.state[companyCanContact]}
              onChange={this.handleFieldChange}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin="dense"
              variant="outlined"
              fullWidth
            >
              {yesNoOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>
          <div className={classes.sectionFieldsContainer}>
            <TextField
              id={`company-reason-leaving-${index}`}
              label="Reason For Leaving"
              name={companyReasonLeaving}
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state[companyReasonLeaving]}
              onChange={this.handleFieldChange}
              multiline
              fullWidth
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Company);
