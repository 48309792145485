import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MobileStep from './MobileStep';
import Hidden from '@material-ui/core/Hidden';

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'column'
  },
  buttonContainer: {
    marginTop: 'auto',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  headingContainer: {
    borderBottom: '1px solid grey',
    marginBottom: theme.spacing(2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  textField: {
    backgroundColor: '#fff',
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0
    }
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2)
  },
  sectionFieldsContainer: {
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  }
});

const resetTimeout = (id, newId) => {
  clearTimeout(id);
  return newId;
};

const levelOptions = [
  {
    value: '',
    label: ''
  },
  {
    value: 'Some High School',
    label: 'Some High School'
  },
  {
    value: 'High School / GED',
    label: 'High School / GED'
  },
  {
    value: 'Some College',
    label: 'Some College'
  },
  {
    value: '2 Year Degree Program',
    label: '2 Year Degree Program'
  },
  {
    value: "Bachelor's",
    label: "Bachelor's"
  },
  {
    value: 'Advanced',
    label: 'Advanced'
  }
];

const yesNoOptions = [
  {
    value: '',
    label: ''
  },
  {
    value: 'Yes',
    label: 'Yes'
  },
  {
    value: 'No',
    label: 'No'
  }
];

export class Education extends Component {
  state = {
    schoolName1: '',
    schoolCityState1: '',
    schoolLevel1: '',
    schoolMajor1: '',
    schoolMinor1: '',
    schoolGpa1: '',
    schoolCompleted1: '',
    schoolStartDate1: '',
    schoolEndDate1: '',

    schoolName2: '',
    schoolCityState2: '',
    schoolLevel2: '',
    schoolMajor2: '',
    schoolMinor2: '',
    schoolGpa2: '',
    schoolCompleted2: '',
    schoolStartDate2: '',
    schoolEndDate2: '',

    schoolCerts1: '',
    schoolCerts2: '',
    schoolCerts3: '',
    schoolCerts4: '',

    timeout: null,
    isComplete: false
  };

  componentDidMount() {
    const { globalState } = this.props;

    window.scrollTo(0, 0);

    this.setState(
      {
        schoolName1: globalState.schoolName1 || '',
        schoolCityState1: globalState.schoolCityState1 || '',
        schoolLevel1: globalState.schoolLevel1 || '',
        schoolMajor1: globalState.schoolMajor1 || '',
        schoolMinor1: globalState.schoolMinor1 || '',
        schoolGpa1: globalState.schoolGpa1 || '',
        schoolCompleted1: globalState.schoolCompleted1 || '',
        schoolStartDate1: globalState.schoolStartDate1 || '',
        schoolEndDate1: globalState.schoolEndDate1 || '',

        schoolName2: globalState.schoolName2 || '',
        schoolCityState2: globalState.schoolCityState2 || '',
        schoolLevel2: globalState.schoolLevel2 || '',
        schoolMajor2: globalState.schoolMajor2 || '',
        schoolMinor2: globalState.schoolMinor2 || '',
        schoolGpa2: globalState.schoolGpa2 || '',
        schoolCompleted2: globalState.schoolCompleted2 || '',
        schoolStartDate2: globalState.schoolStartDate2 || '',
        schoolEndDate2: globalState.schoolEndDate2 || '',

        schoolCerts1: globalState.schoolCerts1 || '',
        schoolCerts2: globalState.schoolCerts2 || '',
        schoolCerts3: globalState.schoolCerts3 || '',
        schoolCerts4: globalState.schoolCerts4 || ''
      },
      this.processStep
    );
  }

  handleFieldChange = ({ target = {} }) => {
    const name = target.name;
    const value = target.value;

    this.setState({ [name]: value }, this.processStep);
  };

  evaluateIscomplete = () => {
    const { schoolName1, schoolCityState1, schoolLevel1, schoolCompleted1 } = this.state;
    const isComplete = !!schoolName1 && !!schoolCityState1 && !!schoolLevel1 && !!schoolCompleted1;

    this.setState({ isComplete });
  };

  processStep = () => {
    const { updateGlobalState } = this.props;
    const {
      schoolName1,
      schoolCityState1,
      schoolLevel1,
      schoolMajor1,
      schoolMinor1,
      schoolGpa1,
      schoolCompleted1,
      schoolStartDate1,
      schoolEndDate1,

      schoolName2,
      schoolCityState2,
      schoolLevel2,
      schoolMajor2,
      schoolMinor2,
      schoolGpa2,
      schoolCompleted2,
      schoolStartDate2,
      schoolEndDate2,

      schoolCerts1,
      schoolCerts2,
      schoolCerts3,
      schoolCerts4,
      timeout
    } = this.state;
    updateGlobalState({
      schoolName1,
      schoolCityState1,
      schoolLevel1,
      schoolMajor1,
      schoolMinor1,
      schoolGpa1,
      schoolCompleted1,
      schoolStartDate1,
      schoolEndDate1,

      schoolName2,
      schoolCityState2,
      schoolLevel2,
      schoolMajor2,
      schoolMinor2,
      schoolGpa2,
      schoolCompleted2,
      schoolStartDate2,
      schoolEndDate2,

      schoolCerts1,
      schoolCerts2,
      schoolCerts3,
      schoolCerts4
    });

    this.setState({
      timeout: resetTimeout(timeout, setTimeout(this.evaluateIscomplete, 300))
    });
  };

  render() {
    const { handleBack, handleNext, classes } = this.props;
    const { isComplete } = this.state;
    return (
      <div className={classes.container}>
        <div className={classes.headingContainer}>
          <Typography variant="h5" gutterBottom color="primary">
            Your education history
          </Typography>
        </div>
        <div className={classes.sectionContainer}>
          <Typography variant="h6" color="primary">
            School #1 (most recent)*
          </Typography>
          <div className={classes.sectionFieldsContainer}>
            <TextField
              required
              id="school-name-1"
              label="School Name"
              name="schoolName1"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              fullWidth
              value={this.state.schoolName1}
              onChange={this.handleFieldChange}
            />
            <TextField
              required
              id="school-city-state-1"
              label="City & State"
              name="schoolCityState1"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.schoolCityState1}
              onChange={this.handleFieldChange}
              fullWidth
            />

            <TextField
              required
              id="school-level-1"
              select
              name="schoolLevel1"
              label="Level"
              className={classes.textField}
              value={this.state.schoolLevel1}
              onChange={this.handleFieldChange}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin="dense"
              variant="outlined"
              fullWidth
            >
              {levelOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>
          <div className={classes.sectionFieldsContainer}>
            <TextField
              id="school-major-1"
              label="Major"
              name="schoolMajor1"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.schoolMajor1}
              onChange={this.handleFieldChange}
              fullWidth
            />
            <TextField
              id="school-minor-1"
              label="Minor"
              name="schoolMinor1"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.schoolMinor1}
              onChange={this.handleFieldChange}
              fullWidth
            />

            <TextField
              id="school-gpa-1"
              label="GPA"
              name="schoolGpa1"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.schoolGpa1}
              onChange={this.handleFieldChange}
              fullWidth
            />
          </div>
          <div className={classes.sectionFieldsContainer}>
            <TextField
              required
              id="school-completed-1"
              select
              name="schoolCompleted1"
              label="Completed"
              className={classes.textField}
              value={this.state.schoolCompleted1}
              onChange={this.handleFieldChange}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin="dense"
              variant="outlined"
              fullWidth
            >
              {yesNoOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>

            <TextField
              id="school-start-date-1"
              label="Start Date: MM/DD/YYYY"
              placeholder="MM/DD/YYYY"
              name="schoolStartDate1"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.schoolStartDate1}
              onChange={this.handleFieldChange}
              fullWidth
            />

            <TextField
              id="school-end-date-1"
              label="End Date: MM/DD/YYYY"
              placeholder="MM/DD/YYYY"
              name="schoolEndDate1"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.schoolEndDate1}
              onChange={this.handleFieldChange}
              fullWidth
            />
          </div>
        </div>

        <div className={classes.sectionContainer}>
          <Typography variant="h6" color="primary">
            School #2
          </Typography>
          <div className={classes.sectionFieldsContainer}>
            <TextField
              id="school-name-2"
              label="School Name"
              name="schoolName2"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              fullWidth
              value={this.state.schoolName2}
              onChange={this.handleFieldChange}
            />
            <TextField
              id="school-city-state-2"
              label="City & State"
              name="schoolCityState2"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.schoolCityState2}
              onChange={this.handleFieldChange}
              fullWidth
            />
            <TextField
              id="school-level-2"
              select
              name="schoolLevel2"
              label="Level"
              className={classes.textField}
              value={this.state.schoolLevel2}
              onChange={this.handleFieldChange}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin="dense"
              variant="outlined"
              fullWidth
            >
              {levelOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>
          <div className={classes.sectionFieldsContainer}>
            <TextField
              id="school-major-2"
              label="Major"
              name="schoolMajor2"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.schoolMajor2}
              onChange={this.handleFieldChange}
              fullWidth
            />
            <TextField
              id="school-minor-2"
              label="Minor"
              name="schoolMinor2"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.schoolMinor2}
              onChange={this.handleFieldChange}
              fullWidth
            />
            <TextField
              id="school-gpa-2"
              label="GPA"
              name="schoolGpa2"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.schoolGpa2}
              onChange={this.handleFieldChange}
              fullWidth
            />
          </div>
          <div className={classes.sectionFieldsContainer}>
            <TextField
              id="school-completed-2"
              label="Completed"
              name="schoolCompleted2"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.schoolCompleted2}
              onChange={this.handleFieldChange}
              fullWidth
            />

            <TextField
              id="school-start-date-2"
              label="Start Date: MM/DD/YYYY"
              placeholder="MM/DD/YYYY"
              name="schoolStartDate2"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.schoolStartDate2}
              onChange={this.handleFieldChange}
              fullWidth
            />

            <TextField
              id="school-end-date-2"
              label="End Date: MM/DD/YYYY"
              placeholder="MM/DD/YYYY"
              name="schoolEndDate2"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.schoolEndDate2}
              onChange={this.handleFieldChange}
              fullWidth
            />
          </div>
        </div>

        <div className={classes.sectionContainer}>
          <Typography variant="h6" color="primary">
            List any certifications or professional designations.
          </Typography>
          <div className={classes.sectionFieldsContainer}>
            <TextField
              id="school-certs-1"
              label="Cert or Designation"
              name="schoolCerts1"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              fullWidth
              value={this.state.schoolCerts1}
              onChange={this.handleFieldChange}
            />
            <TextField
              id="school-certs-2"
              label="Cert or Designation"
              name="schoolCerts2"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              fullWidth
              value={this.state.schoolCerts2}
              onChange={this.handleFieldChange}
            />
            <TextField
              id="school-certs-3"
              label="Cert or Designation"
              name="schoolCerts3"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              fullWidth
              value={this.state.schoolCerts3}
              onChange={this.handleFieldChange}
            />
            <TextField
              id="school-certs-4"
              label="Cert or Designation"
              name="schoolCerts4"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              fullWidth
              value={this.state.schoolCerts4}
              onChange={this.handleFieldChange}
            />
          </div>
        </div>

        <div className={classes.buttonContainer}>
          <Hidden smDown>
            <Button onClick={handleBack} className={classes.backButton}>
              Back
            </Button>
            <Button disabled={!isComplete} variant="contained" color="secondary" onClick={handleNext}>
              Next
            </Button>
          </Hidden>
          <MobileStep
            backDisabled={false}
            nextDisabled={!isComplete}
            steps={this.props.steps}
            activeStep={this.props.activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Education);
