import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import phone from 'phone';

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'column'
  },
  buttonContainer: {
    marginTop: 'auto',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  headingContainer: {
    borderBottom: '1px solid grey',
    marginBottom: theme.spacing(2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  textField: {
    backgroundColor: '#fff',
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0
    }
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2)
  },
  sectionFieldsContainer: {
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  }
});

const yesNoOptions = [
  {
    value: '',
    label: ''
  },
  {
    value: 'Yes',
    label: 'Yes'
  },
  {
    value: 'No',
    label: 'No'
  },
  {
    value: 'Later',
    label: 'Later'
  }
];

const resetTimeout = (id, newId) => {
  clearTimeout(id);
  return newId;
};

export class RequiredCompany extends Component {
  state = {
    companyName1: '',
    companyCityState1: '',
    companyTitle1: '',
    companyStartDate1: '',
    companyEndDate1: '',
    companyContactTitle1: '',
    companyPhoneNumber1: '',
    companyPhoneNumberError1: false,
    companyEmailAddress1: '',
    companyCanContact1: '',
    companyReasonLeaving1: '',
    timeout: null,
    isComplete: false
  };

  componentDidMount() {
    const { globalState } = this.props;

    this.setState(
      {
        companyName1: globalState.companyName1 || '',
        companyCityState1: globalState.companyCityState1 || '',
        companyTitle1: globalState.companyTitle1 || '',
        companyStartDate1: globalState.companyStartDate1 || '',
        companyEndDate1: globalState.companyEndDate1 || '',
        companyContactTitle1: globalState.companyContactTitle1 || '',
        companyPhoneNumber1: globalState.companyPhoneNumber1 || '',
        companyPhoneNumberError1: globalState.companyPhoneNumberError1 || false,
        companyEmailAddress1: globalState.companyEmailAddress1 || '',
        companyCanContact1: globalState.companyCanContact1 || '',
        companyReasonLeaving1: globalState.companyReasonLeaving1 || ''
      },
      this.processStep
    );
  }

  handleFieldChange = ({ target = {} }) => {
    const name = target.name;
    const value = target.value;

    this.setState({ [name]: value }, this.processStep);
  };

  evaluateIscomplete = () => {
    const { handleUpdateIsComplete } = this.props;
    const {
      companyName1,
      companyCityState1,
      companyTitle1,
      companyStartDate1,
      companyEndDate1,
      companyContactTitle1,
      companyPhoneNumberError1,
      companyReasonLeaving1
    } = this.state;
    const isComplete =
      !!companyName1 &&
      !!companyCityState1 &&
      !!companyTitle1 &&
      !!companyStartDate1 &&
      !!companyEndDate1 &&
      !!companyContactTitle1 &&
      !companyPhoneNumberError1 &&
      !!companyReasonLeaving1;

    handleUpdateIsComplete(isComplete);
  };

  processStep = () => {
    const { updateGlobalState } = this.props;
    const {
      companyName1,
      companyCityState1,
      companyTitle1,
      companyStartDate1,
      companyEndDate1,
      companyContactTitle1,
      companyPhoneNumber1,
      companyPhoneNumberError1,
      companyEmailAddress1,
      companyCanContact1,
      companyReasonLeaving1,
      timeout
    } = this.state;

    updateGlobalState({
      companyName1,
      companyCityState1,
      companyTitle1,
      companyStartDate1,
      companyEndDate1,
      companyContactTitle1,
      companyPhoneNumber1,
      companyPhoneNumberError1,
      companyEmailAddress1,
      companyCanContact1,
      companyReasonLeaving1
    });

    this.setState({
      timeout: resetTimeout(timeout, setTimeout(this.evaluateIscomplete, 300))
    });
  };

  handlePhoneNumberChange = ({ target = {} }) => {
    const phoneNumber = target.value || '';
    const name = target.name;
    const errorName = 'companyPhoneNumberError1';

    if (!name) {
      return;
    }

    // this actually returns an array, if array is empty its not valid
    // if array has length, we're good.
    const parsedNumber = phone(phoneNumber);
    const isValid = parsedNumber.length || phoneNumber === '';

    this.setState({ [name]: phoneNumber, [errorName]: !isValid }, this.processStep);
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <div className={classes.sectionContainer}>
          <Typography variant="h6" color="primary">
            Company #1 (most recent)*
          </Typography>
          <div className={classes.sectionFieldsContainer}>
            <TextField
              required
              id="company-name-1"
              label="Company Name"
              name="companyName1"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.companyName1}
              onChange={this.handleFieldChange}
              fullWidth
            />
            <TextField
              required
              id="company-city-state-1"
              label="Company City and State"
              name="companyCityState1"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.companyCityState1}
              onChange={this.handleFieldChange}
              fullWidth
            />
            <TextField
              required
              id="company-title-1"
              label="Your Title"
              name="companyTitle1"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.companyTitle1}
              onChange={this.handleFieldChange}
              fullWidth
            />
          </div>

          <div className={classes.sectionFieldsContainer}>
            <TextField
              required
              id="company-start-date-1"
              label="Start Date: MM/DD/YYYY"
              placeholder="MM/DD/YYYY"
              name="companyStartDate1"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.companyStartDate1}
              onChange={this.handleFieldChange}
              fullWidth
            />
            <TextField
              required
              id="company-end-date-1"
              label="End Date: MM/DD/YYYY"
              name="companyEndDate1"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.companyEndDate1}
              onChange={this.handleFieldChange}
              placeholder="MM/DD/YYYY"
              fullWidth
            />
            <TextField
              required
              id="company-contact-title-1"
              label="Contact Person / Title"
              name="companyContactTitle1"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.companyContactTitle1}
              onChange={this.handleFieldChange}
              fullWidth
            />
          </div>

          <div className={classes.sectionFieldsContainer}>
            <TextField
              id="company-phone-number-1"
              label="Best Phone Number"
              name="companyPhoneNumber1"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.companyPhoneNumber1}
              onChange={this.handlePhoneNumberChange}
              error={this.state.companyPhoneNumberError1}
              placeholder="555-555-5555"
              helperText={this.state.companyPhoneNumberError1 ? 'Please enter a valid phone number: 555-555-5555' : ''}
              fullWidth
            />
            <TextField
              id="company-email-address-1"
              label="Email Address"
              name="companyEmailAddress1"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.companyEmailAddress1}
              onChange={this.handleFieldChange}
              fullWidth
            />

            <TextField
              id="company-can-contact-1"
              select
              name="companyCanContact1"
              label="May we contact them?"
              className={classes.textField}
              value={this.state.companyCanContact1}
              onChange={this.handleFieldChange}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin="dense"
              variant="outlined"
              fullWidth
            >
              {yesNoOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>
          <div className={classes.sectionFieldsContainer}>
            <TextField
              required
              id="company-reason-leaving-1"
              label="Reason For Leaving"
              name="companyReasonLeaving1"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.companyReasonLeaving1}
              onChange={this.handleFieldChange}
              multiline
              fullWidth
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles)(RequiredCompany);
