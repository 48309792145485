import React, { Component } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

export class ApplicationsListItem extends Component {
  render() {
    const { data = {}, style, index } = this.props;
    const { list = [], handleClickApplicant, selectedApplicationId } = data;
    const application = list[index];

    if (!application) {
      return null;
    }

    const firstLetter = application.fullName ? application.fullName.charAt(0) : '?';

    return (
      <ListItem style={style} button selected={selectedApplicationId === application.id} onClick={() => handleClickApplicant(application.id)}>
        <ListItemAvatar>
          <Avatar>{firstLetter}</Avatar>
        </ListItemAvatar>
        <ListItemText primary={application.fullName} secondary={application.position} style={{ whiteSpace: 'nowrap', overflow: 'hidden' }} />
      </ListItem>
    );
  }
}

export default ApplicationsListItem;
