import React, { Component } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import RichTextEditor from 'react-rte';

const styles = theme => ({
  root: {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    flex: '0 0 auto',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '100px'
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column',
    width: theme.container.width.default,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      width: theme.container.width.md
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  contentHeader: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2)
    }
  },
  jobDetails: {
    display: 'flex',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  jobDetailsItem: {
    display: 'list-item',
    listStyleType: 'disc',
    listStylePosition: 'inside',
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      listStyleType: 'none',
      marginLeft: 0
    }
  },
  buttonContainer: {
    marginTop: theme.spacing(3)
  },
  button: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6)
  },
  contentDetails: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.spacing(6),
    maxWidth: '800px',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2)
    }
  },
  paragraph: {
    marginTop: theme.spacing(2),
    '&:first-child': {
      marginTop: theme.spacing(1)
    }
  }
});

export class JobDescription extends Component {
  state = {
    jobPosting: RichTextEditor.createEmptyValue()
  };

  componentDidMount() {
    const { match = {} } = this.props;
    const params = match.params || {};
    const jobListingId = params.jobListingId;

    if (jobListingId) {
      this.fetchPosting(jobListingId);
    }
  }

  fetchPosting = postingId => {
    firebase
      .firestore()
      .collection('jobPostings')
      .doc('postings')
      .collection('postings')
      .doc(postingId)
      .get()
      .then(doc => {
        if (doc.exists) {
          const data = doc.data();

          this.setState({
            jobPosting: RichTextEditor.createValueFromString(data.details, 'html'),
            name: data.name,
            company: data.company,
            location: data.location
          });
        }
      })
      .catch(error => {});
  };

  onChange = value => {
    this.setState({ jobPosting: value });
    if (this.props.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      // value.toString('html')
      this.props.onChange(value);
    }
  };

  render() {
    const { classes } = this.props;
    const { jobPosting, name, company, location } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.contentContainer}>
          <div className={classes.contentHeader}>
            <div>
              <Typography variant="h3" color="primary" style={{ fontWeight: '500' }}>
                {name}
              </Typography>
            </div>
            <div className={classes.jobDetails}>
              <Typography variant="h5" color="primary">
                {company}
              </Typography>
              <Typography variant="h5" color="primary" className={classes.jobDetailsItem}>
                {location}
              </Typography>
            </div>
            <div className={classes.buttonContainer}>
              <Button variant="contained" color="secondary" className={classes.button} href={`/application?position=${encodeURIComponent(name)}`}>
                Apply Now
              </Button>
            </div>
          </div>
          <div className={classes.contentDetails}>
            {jobPosting && <RichTextEditor value={this.state.jobPosting} readOnly={true} style={{ minHeight: '500px' }} />}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(JobDescription);
