import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MobileStep from '../MobileStep';
import Hidden from '@material-ui/core/Hidden';
import RequiredCompany from './RequiredCompany';
import Company from './Company';

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'column'
  },
  buttonContainer: {
    marginTop: 'auto',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  headingContainer: {
    borderBottom: '1px solid grey',
    marginBottom: theme.spacing(2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  textField: {
    backgroundColor: '#fff',
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0
    }
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2)
  },
  sectionFieldsContainer: {
    display: 'flex'
  }
});

export class WorkExperience extends Component {
  state = {
    isComplete: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleUpdateIsComplete = isComplete => {
    this.setState({ isComplete });
  };
  render() {
    const { globalState, updateGlobalState, handleBack, handleNext, classes } = this.props;
    const { isComplete } = this.state;
    return (
      <div className={classes.container}>
        <div className={classes.headingContainer}>
          <Typography variant="h5" gutterBottom color="primary">
            Your work history
          </Typography>
        </div>
        <RequiredCompany globalState={globalState} updateGlobalState={updateGlobalState} handleUpdateIsComplete={this.handleUpdateIsComplete} />
        <Company globalState={globalState} updateGlobalState={updateGlobalState} index={2} />
        <Company globalState={globalState} updateGlobalState={updateGlobalState} index={3} />

        <div className={classes.buttonContainer}>
          <Hidden smDown>
            <Button onClick={handleBack} className={classes.backButton}>
              Back
            </Button>
            <Button disabled={!isComplete} variant="contained" color="secondary" onClick={handleNext}>
              Next
            </Button>
          </Hidden>
          <MobileStep
            backDisabled={false}
            nextDisabled={!isComplete}
            steps={this.props.steps}
            activeStep={this.props.activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(WorkExperience);
