import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import { green } from '@material-ui/core/colors';
import RichTextEditor from 'react-rte';

const styles = theme => ({
  root: {
    height: 'auto',
    width: '100%',
    paddingTop: '8px',
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column'
  },
  container: {
    width: theme.container.width.default,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    alignSelf: 'center'
  },
  companySelect: {
    marginRight: theme.spacing(2)
  },
  editor: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  snackbar: {
    backgroundColor: green[600]
  }
});

const COMPANIES = ['', 'd.e. Foxx & Associates', 'Versatex', 'XLC Services', 'FX Facility Group'];
const LOCATIONS = [
  '',
  'Downtown Cincinnati, OH (paid parking included)',
  'Cincinnati, OH',
  'Richmond, VA',
  'Greensboro, NC',
  'Albany, GA',
  'LaGrange, GA',
  'Andover, MA',
  'Syracuse, NY',
  'Martinsburg, WV',
  'Mehoopany, PA'
];

export class EditPosting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: RichTextEditor.createEmptyValue(),
      positionName: '',
      positionCompany: '',
      positionLocation: '',
      positionActive: false,
      postingId: '',
      success: false,
      sortOrder: null
    };
  }

  componentDidMount() {
    const { match = {} } = this.props;
    const params = match.params || {};
    const jobPostingId = params.jobPostingId;

    if (jobPostingId) {
      this.fetchPosting(jobPostingId);
    }
  }

  fetchPosting = postingId => {
    firebase
      .firestore()
      .collection('jobPostings')
      .doc('postings')
      .collection('postings')
      .doc(postingId)
      .get()
      .then(doc => {
        if (doc.exists) {
          const data = doc.data();
          const positionName = data.name || '';
          const positionCompany = data.company || '';
          const positionLocation = data.location || '';
          const positionActive = data.active || false;

          this.setState({
            value: RichTextEditor.createValueFromString(data.details, 'html'),
            positionName,
            positionCompany,
            positionLocation,
            positionActive,
            postingId
          });
        }
      })
      .catch(error => {});

    firebase
      .firestore()
      .collection('jobPostings')
      .doc('postings')
      .collection('postingsIndex')
      .doc(postingId)
      .get()
      .then(doc => {
        if (doc.exists) {
          const data = doc.data();
          const sortOrder = data.sortOrder || null;

          this.setState({
            sortOrder
          });
        }
      })
      .catch(error => {});
  };

  onChange = value => {
    this.setState({ value });
    // if (this.props.onChange) {
    //   // Send the changes up to the parent component as an HTML string.
    //   // This is here to demonstrate using `.toString()` but in a real app it
    //   // would be better to avoid generating a string on each change.
    //   // value.toString('html')
    //   this.props.onChange(value);
    // }
  };

  handleChange = ({ target = {} }) => {
    const name = target.name;
    let value = target.value;

    if (name === 'positionActive') {
      value = target.checked;
    }

    this.setState({ [name]: value });
  };

  onSave = async () => {
    const { value, positionName, positionCompany, positionLocation, positionActive, postingId } = this.state;

    const data = {
      name: positionName,
      company: positionCompany,
      location: positionLocation,
      active: positionActive,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    };
    const id = postingId ? postingId : undefined;

    if (!id) {
      data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
    }

    const db = firebase.firestore();
    const batch = db.batch();
    const postingPath = db
      .collection('jobPostings')
      .doc('postings')
      .collection('postings');
    const postingsIndexPath = db
      .collection('jobPostings')
      .doc('postings')
      .collection('postingsIndex');
    let sortOrder = this.state.sortOrder;
    if (sortOrder === null) {
      await postingsIndexPath
        .orderBy('sortOrder', 'asc')
        .get()
        .then(snap => {
          let i = 1;
          snap.forEach(doc => {
            batch.set(postingsIndexPath.doc(doc.id), { sortOrder: i }, { merge: true });
            i++;
          });
        });
      sortOrder = 0;
    }

    let postingRef = null;
    let postingIndexRef = null;

    if (id) {
      postingRef = postingPath.doc(id);
      postingIndexRef = postingsIndexPath.doc(id);
    } else {
      postingRef = postingPath.doc();
      postingIndexRef = postingsIndexPath.doc(postingRef.id);
    }

    batch.set(postingRef, { ...data, details: value.toString('html') });
    batch.set(postingIndexRef, { ...data, sortOrder });

    const finalId = id || postingRef.id;

    batch
      .commit()
      .then((a, b, c) => {
        this.setState({ success: true, postingId: finalId });
      })
      .catch(error => {
        this.setState({ error: true });
      });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ success: false });
  };

  render() {
    const { classes } = this.props;
    const { positionName, positionCompany, positionLocation, positionActive } = this.state;
    return (
      <div className={classes.root}>
        <div>
          <Button className={classes.button} href="/job-postings">
            <ArrowBackIcon className={classes.leftIcon} />
            Job Postings
          </Button>
        </div>
        <div className={classes.container}>
          <div>
            <TextField
              id="job-position"
              label="Position Name"
              name="positionName"
              className={classes.textField}
              value={positionName}
              onChange={this.handleChange}
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </div>
          <div>
            <TextField
              id="job-company"
              select
              label="Company/Brand"
              value={positionCompany}
              onChange={this.handleChange}
              name="positionCompany"
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu
                }
              }}
              helperText=""
              margin="dense"
              variant="outlined"
            >
              {COMPANIES.map(company => (
                <option key={company} value={company}>
                  {company}
                </option>
              ))}
            </TextField>
            {/* <TextField
              id="job-company"
              label="Company/Brand"
              name="positionCompany"
              style={{ marginTop: 0, marginRight: '16px' }}
              value={positionCompany}
              onChange={this.handleChange}
              margin="dense"
              variant="outlined"
            /> */}

            <TextField
              id="job-city"
              select
              label="Location"
              value={positionLocation}
              onChange={this.handleChange}
              name="positionLocation"
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu
                }
              }}
              helperText=""
              margin="dense"
              variant="outlined"
            >
              {LOCATIONS.map(location => (
                <option key={location} value={location}>
                  {location}
                </option>
              ))}
            </TextField>

            {/* <TextField
              id="job-city"
              label="Location"
              name="positionLocation"
              style={{ marginTop: 0, marginRight: '32px' }}
              value={positionLocation}
              onChange={this.handleChange}
              margin="dense"
              variant="outlined"
            /> */}

            <FormControlLabel control={<Checkbox name="positionActive" checked={positionActive} onChange={this.handleChange} />} label="Active" />
          </div>
          <div>
            <div className={classes.editor}>
              <Button variant="contained" color="secondary" className={classes.button} onClick={this.onSave}>
                Save
              </Button>
              <RichTextEditor value={this.state.value} onChange={this.onChange} style={{ minHeight: '500px' }} />
            </div>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          open={this.state.success}
          autoHideDuration={6000}
          onClose={this.handleCloseSnackbar}
          variant="success"
          message="Job Posting Saved!!"
          ContentProps={{
            classes: {
              root: classes.snackbar
            }
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(EditPosting);
