import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import PrintIcon from '@material-ui/icons/Print';
import Button from '@material-ui/core/Button';
import * as firebase from 'firebase/app';
import 'firebase/storage';

const styles = theme => ({
  button: {
    margin: theme.spacing(1)
  }
});

export class Resume extends Component {
  state = {
    resumeDownloadUrl: '',
    fetching: false,
    noResume: false
  };

  fetchResume = resumeFilePath => {
    if (!resumeFilePath) {
      return;
    }

    this.setState({ fetching: true });

    firebase
      .storage()
      .ref()
      .child(resumeFilePath)
      .getDownloadURL()
      .then(url => {
        this.setState({ resumeDownloadUrl: url, fetching: false });
      })
      .catch(error => {
        this.setState({ noResume: true, fetching: false });
      });
  };

  componentDidMount() {
    const { resumeFilePath } = this.props;
    if (resumeFilePath) {
      this.fetchResume(this.props.resumeFilePath);
    } else {
      this.setState({ noResume: true });
    }
  }

  componentDidUpdate(prevProps) {
    const { resumeFilePath } = this.props;
    if (!!resumeFilePath && resumeFilePath !== prevProps.resumeFilePath) {
      this.fetchResume(this.props.resumeFilePath);
    }
  }

  render() {
    const { classes } = this.props;
    const { resumeDownloadUrl, noResume, fetching } = this.state;

    let text = 'Fetching';
    let disabled = true;

    if (noResume) {
      text = 'No Resume';
    } else if (!!resumeDownloadUrl && !fetching) {
      text = 'Print Resume';
      disabled = false;
    }

    return (
      <Button disabled={disabled} variant="contained" color="default" size="small" href={resumeDownloadUrl} className={classes.button}>
        <PrintIcon className={classes.leftIcon} />
        {text}
      </Button>
    );
  }
}

export default withStyles(styles)(Resume);
