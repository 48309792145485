import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MobileStep from './MobileStep';
import Hidden from '@material-ui/core/Hidden';

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'column'
  },
  buttonContainer: {
    marginTop: 'auto',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  headingContainer: {
    borderBottom: '1px solid grey',
    marginBottom: theme.spacing(2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  textField: {
    backgroundColor: '#fff',
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0
    }
  },
  lineItemTextField: {
    backgroundColor: '#fff',
    marginLeft: '2rem',
    marginRight: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto'
    },
    '&:last-child': {
      marginRight: 0
    }
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),

    background: 'rgba(255,255,255,0.4)'
  },
  sectionFieldsContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  lineItemContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  lineItemText: {
    [theme.breakpoints.down('sm')]: {
      marginRight: '2rem'
    }
  }
});

const yesNoOptions = [
  {
    value: '',
    label: ''
  },
  {
    value: 'Yes',
    label: 'Yes'
  },
  {
    value: 'No',
    label: 'No'
  }
];

export class GeneralQuestions extends Component {
  state = {
    backgroundBeenConvictedFelony: '',
    backgroundConvictionFelonyDetails: '',
    backgroundBeenConvictedMisdemeanor: '',
    backgroundConvictionMisdemeanorDetails: '',
    backgroundOutstandingLitigation: '',

    generalLegallyWorkInUs: '',
    generalAtLeast18: '',
    generalWillingToTravel: '',
    generalWillingToTravelDetails: '',
    generalTargetSalary: '',
    generalAvailableStartDate: '',
    generalRequireSponsorship: '',
    isComplete: false
  };

  componentDidMount() {
    const { globalState } = this.props;

    window.scrollTo(0, 0);

    this.setState(
      {
        backgroundBeenConvictedFelony: globalState.backgroundBeenConvictedFelony || '',
        backgroundConvictionFelonyDetails: globalState.backgroundConvictionFelonyDetails || '',
        backgroundBeenConvictedMisdemeanor: globalState.backgroundBeenConvictedMisdemeanor || '',
        backgroundConvictionMisdemeanorDetails: globalState.backgroundConvictionMisdemeanorDetails || '',
        backgroundOutstandingLitigation: globalState.backgroundOutstandingLitigation || '',

        generalLegallyWorkInUs: globalState.generalLegallyWorkInUs || '',
        generalAtLeast18: globalState.generalAtLeast18 || '',
        generalWillingToTravel: globalState.generalWillingToTravel || '',
        generalWillingToTravelDetails: globalState.generalWillingToTravelDetails || '',
        generalTargetSalary: globalState.generalTargetSalary || '',
        generalAvailableStartDate: globalState.generalAvailableStartDate || '',
        generalRequireSponsorship: globalState.generalRequireSponsorship || ''
      },
      this.processStep
    );
  }

  handleFieldChange = ({ target = {} }) => {
    const name = target.name;
    const value = target.value;

    this.setState({ [name]: value }, this.processStep);
  };

  processStep = () => {
    const { updateGlobalState } = this.props;
    const {
      backgroundBeenConvictedFelony,
      backgroundConvictionFelonyDetails,
      backgroundBeenConvictedMisdemeanor,
      backgroundConvictionMisdemeanorDetails,
      backgroundOutstandingLitigation,

      generalLegallyWorkInUs,
      generalAtLeast18,
      generalWillingToTravel,
      generalWillingToTravelDetails,
      generalTargetSalary,
      generalAvailableStartDate,
      generalRequireSponsorship
    } = this.state;
    const isComplete =
      !!backgroundBeenConvictedFelony &&
      !!backgroundBeenConvictedMisdemeanor &&
      !!backgroundOutstandingLitigation &&
      !!generalLegallyWorkInUs &&
      !!generalAtLeast18 &&
      !!generalTargetSalary &&
      !!generalAvailableStartDate &&
      !!generalRequireSponsorship;
    updateGlobalState({
      backgroundBeenConvictedFelony,
      backgroundConvictionFelonyDetails,
      backgroundBeenConvictedMisdemeanor,
      backgroundConvictionMisdemeanorDetails,
      backgroundOutstandingLitigation,

      generalLegallyWorkInUs,
      generalAtLeast18,
      generalWillingToTravel,
      generalWillingToTravelDetails,
      generalTargetSalary,
      generalAvailableStartDate,
      generalRequireSponsorship
    });
    this.setState({ isComplete });
  };

  render() {
    const { handleBack, handleNext, classes } = this.props;
    const { isComplete } = this.state;
    return (
      <div className={classes.container}>
        <div className={classes.headingContainer}>
          <Typography variant="h5" gutterBottom color="primary">
            Background and General Information
          </Typography>
        </div>
        <div className={classes.sectionContainer}>
          <Typography variant="h6" color="primary">
            Background - Being found guilty of a charge does not automatically disqualify you from employment.
          </Typography>
          <div className={classes.sectionFieldsContainer}>
            <div className={classes.lineItemContainer}>
              <Typography variant="body1" color="primary" className={classes.lineItemText}>
                Have you been convicted or pled guilty to a felony within the last 7 years?*
              </Typography>
              <TextField
                required
                id="background-Been-Convicted-Felony"
                select
                name="backgroundBeenConvictedFelony"
                label="Yes / No"
                className={classes.lineItemTextField}
                value={this.state.backgroundBeenConvictedFelony}
                onChange={this.handleFieldChange}
                SelectProps={{
                  native: true,
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="dense"
                variant="outlined"
              >
                {yesNoOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
          </div>
          <div className={classes.sectionFieldsContainer}>
            <TextField
              id="background-been-convicted-felony-details"
              label="If yes, please explain, including charge(s) and dates:"
              name="backgroundConvictionFelonyDetails"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              fullWidth
              multiline
              value={this.state.backgroundConvictionFelonyDetails}
              onChange={this.handleFieldChange}
            />
          </div>

          <div className={classes.sectionFieldsContainer} style={{ marginTop: '1rem' }}>
            <div className={classes.lineItemContainer}>
              <Typography variant="body1" color="primary" className={classes.lineItemText}>
                Have you been convicted or pled guilty to a misdemeanor within the last 3 years?*
              </Typography>
              <TextField
                required
                id="background-Been-Convicted-Misdemeanor"
                select
                name="backgroundBeenConvictedMisdemeanor"
                label="Yes / No"
                className={classes.lineItemTextField}
                value={this.state.backgroundBeenConvictedMisdemeanor}
                onChange={this.handleFieldChange}
                SelectProps={{
                  native: true,
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="dense"
                variant="outlined"
              >
                {yesNoOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
          </div>
          <div className={classes.sectionFieldsContainer}>
            <TextField
              id="background-been-convicted-misdemeanor-details"
              label="If yes, please explain, including charge(s) and dates:"
              name="backgroundConvictionMisdemeanorDetails"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              fullWidth
              multiline
              value={this.state.backgroundConvictionMisdemeanorDetails}
              onChange={this.handleFieldChange}
            />
          </div>

          <div className={classes.sectionFieldsContainer} style={{ marginTop: '1rem' }}>
            <div className={classes.lineItemContainer}>
              <Typography variant="body1" color="primary" className={classes.lineItemText}>
                Are you involved in any outstanding litigation that may result in a felony or misdemeanor charge?*
              </Typography>
              <TextField
                required
                id="background-outstanding-litigation"
                select
                name="backgroundOutstandingLitigation"
                label="Yes / No"
                className={classes.lineItemTextField}
                value={this.state.backgroundOutstandingLitigation}
                onChange={this.handleFieldChange}
                SelectProps={{
                  native: true,
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="dense"
                variant="outlined"
              >
                {yesNoOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
          </div>
          <br />
          <div className={classes.headingContainer} />
        </div>

        <div className={classes.sectionContainer}>
          <Typography variant="h6" color="primary">
            General Questions
          </Typography>
          <div className={classes.sectionFieldsContainer}>
            <div className={classes.lineItemContainer}>
              <Typography variant="body1" color="primary" className={classes.lineItemText}>
                Are you legally authorized to work in the U.S.?*
              </Typography>
              <TextField
                required
                id="general-Legally-Work-In-Us"
                select
                name="generalLegallyWorkInUs"
                label="Yes / No"
                className={classes.lineItemTextField}
                value={this.state.generalLegallyWorkInUs}
                onChange={this.handleFieldChange}
                SelectProps={{
                  native: true,
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="dense"
                variant="outlined"
              >
                {yesNoOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
          </div>

          <div className={classes.sectionFieldsContainer} style={{ marginTop: '1rem' }}>
            <div className={classes.lineItemContainer}>
              <Typography variant="body1" color="primary" className={classes.lineItemText}>
                Are you at least 18 years of age?*
              </Typography>
              <TextField
                required
                id="general-At-Least-18"
                select
                name="generalAtLeast18"
                label="Yes / No"
                className={classes.lineItemTextField}
                value={this.state.generalAtLeast18}
                onChange={this.handleFieldChange}
                SelectProps={{
                  native: true,
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="dense"
                variant="outlined"
              >
                {yesNoOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
          </div>

          <div className={classes.sectionFieldsContainer} style={{ marginTop: '1rem' }}>
            <div className={classes.lineItemContainer}>
              <Typography variant="body1" color="primary" className={classes.lineItemText}>
                Would you require sponsorship to work in the U.S now or at any time in the future?*
              </Typography>

              <TextField
                required
                id="general-Require-Sponsorship"
                select
                name="generalRequireSponsorship"
                label="Yes / No"
                className={classes.lineItemTextField}
                value={this.state.generalRequireSponsorship}
                onChange={this.handleFieldChange}
                SelectProps={{
                  native: true,
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="dense"
                variant="outlined"
              >
                {yesNoOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
          </div>

          <div className={classes.sectionFieldsContainer} style={{ marginTop: '1rem' }}>
            <div className={classes.lineItemContainer}>
              <Typography variant="body1" color="primary" className={classes.lineItemText}>
                Are you willing to travel? If yes, what percent?*
              </Typography>
              <TextField
                required
                id="general-Willing-To-Travel"
                select
                name="generalWillingToTravel"
                label="Yes / No"
                className={classes.lineItemTextField}
                value={this.state.generalWillingToTravel}
                onChange={this.handleFieldChange}
                SelectProps={{
                  native: true,
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="dense"
                variant="outlined"
              >
                {yesNoOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
              <TextField
                id="general-Willing-To-Travel-Details"
                label="What percent?"
                name="generalWillingToTravelDetails"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={this.state.generalWillingToTravelDetails}
                onChange={this.handleFieldChange}
              />
            </div>
          </div>

          <div className={classes.sectionFieldsContainer} style={{ marginTop: '1rem' }}>
            <TextField
              required
              id="general-Target-Salary"
              label="What is your desired hourly wage or annual salary?"
              name="generalTargetSalary"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.generalTargetSalary}
              onChange={this.handleFieldChange}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
            />
            <TextField
              required
              id="general-Available-Start-Date"
              label="What date are you available to start?"
              name="generalAvailableStartDate"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.generalAvailableStartDate}
              onChange={this.handleFieldChange}
              fullWidth
            />
          </div>
        </div>

        <div className={classes.buttonContainer}>
          <Hidden smDown>
            <Button onClick={handleBack} className={classes.backButton}>
              Back
            </Button>
            <Button disabled={!isComplete} variant="contained" color="secondary" onClick={handleNext}>
              Next
            </Button>
          </Hidden>
          <MobileStep
            backDisabled={false}
            nextDisabled={!isComplete}
            steps={this.props.steps}
            activeStep={this.props.activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(GeneralQuestions);
