import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import NavBar from '../components/NavBar';
import List from './List';
import JobDescription from './JobDescription';
import { Switch, Route } from 'react-router-dom';

const styles = theme => ({
  root: {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    flex: '0 0 auto'
  },
  heroContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  heroText: {
    color: '#FFFFFF',
    marginBottom: theme.spacing(2)
  },
  heroAction: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  heroCompanySelectRoot: {
    width: '300px',
    background: '#fff',
    borderRadius: '5px',
    color: '#000'
  },
  contentContainer: {
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column',
    alignItems: 'center',
    width: theme.container.width.default,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      width: theme.container.width.md
    }
  },
  contentTitle: {
    marginBottom: theme.spacing(6)
  },
  listItems: {
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  }
});

export class Careers extends Component {
  state = { selectedPosition: '' };

  updateSelectedPosition = ({ target = {} }) => {
    const name = target.name;
    const value = target.value;

    this.setState({ [name]: value });
  };

  render() {
    const { classes, match } = this.props;
    return (
      <div className={classes.root}>
        <NavBar />
        <Switch>
          <Route exact path={match.path} component={List} />
          <Route path={`${match.path}/:jobListingId`} component={JobDescription} />
        </Switch>
      </div>
    );
  }
}

export default withStyles(styles)(Careers);
