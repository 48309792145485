import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import MobileStep from './MobileStep';

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'column'
  },
  buttonContainer: {
    marginTop: 'auto',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  headingContainer: {
    borderBottom: '1px solid grey',
    marginBottom: theme.spacing(2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  textField: {
    backgroundColor: '#fff',
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0
    }
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2)
  },
  sectionFieldsContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  }
});

const referralOptions = [
  {
    value: '',
    label: ''
  },
  {
    value: 'Family Member',
    label: 'Family Member'
  },
  {
    value: 'Friend',
    label: 'Friend'
  },
  {
    value: 'Indeed',
    label: 'Indeed'
  },
  {
    value: 'Zip Recruiter',
    label: 'Zip Recruiter'
  },
  {
    value: 'LinkedIn',
    label: 'LinkedIn'
  },
  {
    value: 'Community Organization',
    label: 'Community Organization'
  },
  {
    value: 'Facebook',
    label: 'Facebook'
  },
  {
    value: 'Other',
    label: 'Other'
  }
];

export class Name extends Component {
  state = {
    firstName: '',
    lastName: '',
    middleInitial: '',
    suffix: '',
    position: '',

    referredBy: '',
    referredByName: '',
    isComplete: false
  };

  componentDidMount() {
    const { globalState, location } = this.props;
    let position = '';
    try {
      let search = new URLSearchParams(location.search);
      position = search.get('position') || '';
    } catch (e) {
      console.log(e);
      position = '';
    }
    window.scrollTo(0, 0);

    this.setState(
      {
        firstName: globalState.firstName || '',
        lastName: globalState.lastName || '',
        middleInitial: globalState.middleInitial || '',
        suffix: globalState.suffix || '',
        position: globalState.position || position,

        referredBy: globalState.referredBy || '',
        referredByName: globalState.referredByName || ''
      },
      this.processStep
    );
  }

  handleFieldChange = ({ target = {} }) => {
    const name = target.name;
    const value = target.value;

    this.setState({ [name]: value }, this.processStep);
  };

  processStep = () => {
    const { updateGlobalState } = this.props;
    const { firstName, lastName, middleInitial, suffix, position, referredBy, referredByName } = this.state;
    const isComplete = !!firstName && !!lastName && !!position && !!referredBy;
    updateGlobalState({ firstName, lastName, middleInitial, suffix, position, referredBy, referredByName });
    this.setState({ isComplete });
  };

  render() {
    const { handleBack, handleNext, steps, activeStep, classes } = this.props;
    const { isComplete } = this.state;
    return (
      <div className={classes.container}>
        <div className={classes.headingContainer}>
          <Typography variant="h5" gutterBottom color="primary">
            Reasonable Accomodations and Your Name
          </Typography>
        </div>
        <div className={classes.sectionContainer}>
          <Typography variant="h6" color="primary">
            Reasonable Accomodations
          </Typography>
          <Typography variant="body2" color="primary">
            State and Federal law requires employers to provide reasonable accommodation to qualified individuals with disabilities. Please tell us if
            you require a reasonable accommodation to apply for a job or to perform the essential functions of your job. Examples of reasonable
            accommodation include making a change to the application process or work procedures, providing documents in an alternate format, using a
            sign language interpreter, or using specialized equipment. Please contact us at (513)639-8810 if accomodation is required.
          </Typography>
        </div>
        <Typography variant="h6" color="primary">
          Your Name*
        </Typography>
        <div className={classes.sectionContainer}>
          <div className={classes.sectionFieldsContainer}>
            <TextField
              required
              id="first-name-required"
              label="First Name"
              name="firstName"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              fullWidth
              value={this.state.firstName}
              onChange={this.handleFieldChange}
              error={this.state.firstNameError}
              helperText={this.state.firstNameError ? 'This field is required' : null}
            />
            <TextField
              id="middle-initial"
              label="MI"
              name="middleInitial"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.middleInitial}
              onChange={this.handleFieldChange}
            />
            <TextField
              required
              id="last-name-required"
              label="Last Name"
              name="lastName"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              fullWidth
              value={this.state.lastName}
              onChange={this.handleFieldChange}
              error={this.state.lastNameError}
              helperText={this.state.lastNameError ? 'This field is required' : null}
            />
            <TextField
              id="suffix"
              label="Suffix"
              name="suffix"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.suffix}
              onChange={this.handleFieldChange}
            />
          </div>
        </div>

        <div className={classes.sectionContainer}>
          <Typography variant="h6" color="primary">
            What position are you applying for?*
          </Typography>
          <div className={classes.sectionFieldsContainer}>
            <TextField
              required
              id="position-required"
              label="Position Applying For?"
              name="position"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              fullWidth
              value={this.state.position}
              onChange={this.handleFieldChange}
            />
          </div>
        </div>
        <div className={classes.sectionContainer}>
          <Typography variant="h6" color="primary">
            How did you hear about D.E. Foxx?*
          </Typography>
          <div className={classes.sectionFieldsContainer}>
            <TextField
              required
              id="referred-by"
              select
              name="referredBy"
              label="Choose one"
              className={classes.textField}
              value={this.state.referredBy}
              onChange={this.handleFieldChange}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin="dense"
              variant="outlined"
              error={this.state.referredByError}
              helperText={this.state.referredByError ? 'This field is required' : null}
            >
              {referralOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>
        </div>

        <div className={classes.sectionContainer}>
          <Typography variant="h6" color="primary">
            If referred, please list name of the specific person or organization?
          </Typography>
          <div className={classes.sectionFieldsContainer}>
            <TextField
              id="referred-by-name"
              label="Referred By: "
              name="referredByName"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              value={this.state.referredByName}
              onChange={this.handleFieldChange}
              fullWidth
            />
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Hidden smDown>
            <Button disabled onClick={handleBack} className={classes.backButton}>
              Back
            </Button>
            <Button disabled={!isComplete} variant="contained" color="secondary" onClick={handleNext}>
              Next
            </Button>
          </Hidden>
          <MobileStep
            backDisabled={true}
            nextDisabled={!isComplete}
            steps={steps}
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Name);
