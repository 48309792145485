import React, { Component } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { withStyles } from '@material-ui/styles';
import hero from '../static/images/careers-hero-600.png';
import JobListingResult from './JobListingResult';
import Typography from '@material-ui/core/Typography';
// import TextField from '@material-ui/core/TextField';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import NativeSelect from '@material-ui/core/NativeSelect';
// import InputBase from '@material-ui/core/InputBase';

const styles = theme => ({
  root: {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    flex: '0 0 auto'
  },
  hero: {
    height: '600px',
    width: '100%',
    backgroundImage: `url(${hero})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    content: '""',
    display: 'flex',
    alignItems: 'center'
  },
  heroContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  heroText: {
    color: '#FFFFFF',
    marginBottom: theme.spacing(2),
    textAlign: 'center'
  },
  heroAction: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  heroCompanySelectRoot: {
    width: '300px',
    background: '#fff',
    borderRadius: '5px',
    color: '#000'
  },
  contentContainer: {
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column',
    alignItems: 'center',
    width: theme.container.width.default,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      width: theme.container.width.md
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  contentTitle: {
    marginBottom: theme.spacing(6)
  },
  listItems: {
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  }
});

// const BootstrapInput = withStyles(theme => ({
//   root: {
//     'label + &': {
//       marginTop: theme.spacing(3)
//     }
//   },
//   input: {
//     color: theme.palette.primary.main,
//     borderRadius: '5px',
//     position: 'relative',
//     backgroundColor: '#fff',
//     border: '1px solid #ced4da',
//     fontSize: 16,
//     padding: '10px 26px 10px 12px',
//     transition: theme.transitions.create(['border-color', 'box-shadow']),
//     '&:focus': {
//       borderRadius: 4,
//       borderColor: '#80bdff',
//       boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
//     }
//   }
// }))(InputBase);

// const positionOptions = [
//   {
//     value: '',
//     label: 'Choose a position'
//   },
//   {
//     value: 'd.e. Foxx',
//     label: 'd.e. Foxx'
//   },
//   {
//     value: 'XLC',
//     label: 'XLC'
//   },
//   {
//     value: 'All',
//     label: 'All'
//   }
// ];

export class List extends Component {
  state = { jobPostings: [], fetchingJobPostingsIndex: true };

  state = {
    jobPostings: [],
    fetchingJobPostingsIndex: true
  };

  componentDidMount() {
    this.fetchJobPostingsIndex();
  }

  fetchJobPostingsIndex = () => {
    this.setState({ fetchingJobPostingsIndex: true });
    firebase
      .firestore()
      .collection('jobPostings')
      .doc('postings')
      .collection('postingsIndex')
      .where('active', '==', true)
      .orderBy('sortOrder', 'asc')
      .get()
      .then(snapshot => {
        const jobPostingsIndex = [];
        snapshot.forEach(doc => {
          const jobPosting = doc.data();
          jobPosting.id = doc.id;
          jobPostingsIndex.push(jobPosting);
        });
        this.setState({ jobPostings: jobPostingsIndex, fetchingJobPostingsIndex: false });
      })
      .catch(error => {});
  };

  updateSelectedPosition = ({ target = {} }) => {
    const name = target.name;
    const value = target.value;

    this.setState({ [name]: value });
  };

  render() {
    const { match, classes } = this.props;
    const { jobPostings } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.hero}>
          <div className={classes.heroContent}>
            <div className={classes.heroText}>
              <Typography variant="h1" style={{ fontWeight: 400 }}>
                Improving All We Touch
              </Typography>
            </div>
            {/* <div className={classes.heroAction}>
              <NativeSelect
                name="selectedPosition"
                className={classes.heroCompanySelectRoot}
                value={selectedPosition}
                onChange={this.updateSelectedPosition}
                placeholder="Choose a brand"
                input={<BootstrapInput name="selectedPosition" id="position-select" />}
              >
                {positionOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </NativeSelect>
            </div> */}
          </div>
        </div>
        <div className={classes.contentContainer}>
          <div className={classes.contentTitle}>
            <Typography variant="h4" color="primary">
              {`${jobPostings.length} Open Positions`}
            </Typography>
          </div>
          <div className={classes.listItems}>
            {jobPostings.map(posting => (
              <JobListingResult key={posting.id} posting={posting} match={match} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(List);
